import {
  DefaultButton,
  Dialog,
  DialogFooter,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  getTheme
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { locale } from "./../../../shared/common";
import { locale as appDockLocale } from "./../../../shared/appdock";

const theme = getTheme();

export const ProductEditPanel = ({
  canDelete,
  canSave,
  children,
  hasChanged,
  isOpen,
  isSaving,
  product,
  onDismiss,
  onDelete,
  onSave
}) => {
  const { t } = useTranslation(locale.namespace);
  const { t: tAppDock } = useTranslation(appDockLocale.namespace);

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <Panel
      isOpen={isOpen}
      type={PanelType.medium}
      onDismiss={() => {
        onDismiss();
      }}
      onRenderFooterContent={() => (
        <Stack
          horizontal
          tokens={{
            childrenGap: theme.spacing.s1
          }}
        >
          <Stack.Item>
            <PrimaryButton
              disabled={!canSave || !hasChanged || isSaving}
              onClick={() => {
                onSave();
              }}
            >
              {t(locale.resource.button.save)}
            </PrimaryButton>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              disabled={isSaving}
              onClick={() => {
                onDismiss();
              }}
            >
              {t(locale.resource.button.cancel)}
            </DefaultButton>
          </Stack.Item>
          {canDelete && !R.isNil(product) && (
            <Stack.Item styles={{ root: { marginLeft: "auto !important" } }}>
              <PrimaryButton
                onClick={() => {
                  setIsDialogVisible(true);
                }}
              >
                {t(locale.resource.button.delete)}
              </PrimaryButton>
            </Stack.Item>
          )}
        </Stack>
      )}
    >
      {children}
      <Dialog
        hidden={!isDialogVisible}
        dialogContentProps={{
          title: tAppDock(
            appDockLocale.resource.productForm.deleteDialog.title
          ),
          subText: tAppDock(
            appDockLocale.resource.productForm.deleteDialog.body,
            { app: R.propOr("", "name", product) }
          )
        }}
        onDismiss={() => {
          setIsDialogVisible(false);
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setIsDialogVisible(false);

              onDelete();
            }}
          >
            {t(locale.resource.button.delete)}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              setIsDialogVisible(false);
            }}
          >
            {t(locale.resource.button.cancel)}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};
