import * as R from "ramda";
import { AuthenticationContext } from "react-adal";

import { environment } from "./../utils";

const createAdalConfiguration = (options = null) => ({
  tenant: environment.adalTenant,
  clientId: environment.adalClientId,
  endpoints: {},
  loginResource: "",
  redirectUri: `${window.location.origin}/auth/authorize`,
  postLogoutRedirectUri: `${window.location.origin}/`,
  isAngular: false,
  cacheLocation: "localStorage",
  ...R.defaultTo({}, options)
});

export const createAdalAuthenticationContext = (options = null) =>
  new AuthenticationContext(createAdalConfiguration(options));
