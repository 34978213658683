import { useStoreActions, useStoreState } from "easy-peasy";
import * as R from "ramda";
import React, { useState } from "react";

import { ProductForm } from "./form";
import { ProductEditPanel } from "./panel";
import { Privilege, ProtectedResource } from "./../../../shared/appdock";

const isNilOrEmpty = value => R.isNil(value) || R.isEmpty(value);

const useValidator = props => {
  const required = prop => !isNilOrEmpty(R.prop(prop, props));
  const isNumber = path => {
    const value = R.path(path, props);
    if (value === undefined) {
      return true;
    }

    const number = Number(value);

    return !isNaN(number) && R.is(Number, number);
  };

  const validatedProps = {
    name: required("name"),
    categories: required("categories"),
    regions: required("regions"),
    minDownload: isNumber(["requirements", "bandwidth", "minDownload"]),
    minUpload: isNumber(["requirements", "bandwidth", "minUpload"]),
    minLatency: isNumber(["requirements", "bandwidth", "minLatency"])
  };

  return [
    validatedProps,
    R.all(
      R.equals(true),
      R.map(key => R.prop(key, validatedProps), R.keys(validatedProps))
    )
  ];
};

const useChangeTracker = (product, form) => {
  if (R.isEmpty(R.keys(form))) {
    return false;
  }

  return R.not(R.equals(product, form));
};

export const ProductEditPanelContainer = () => {
  const uiProductBeingEdited = useStoreState(
    state => state.appDock.uiProductBeingEdited
  );

  const canDeleteProduct = useStoreState(state =>
    state.authentication.hasPrivileges(ProtectedResource.appdock, [
      Privilege.deleteProduct
    ])
  );

  const categories = useStoreState(state => state.appDock.categories);
  const regions = useStoreState(state => state.appDock.regions);
  const product = useStoreState(state =>
    state.appDock.getProductById(uiProductBeingEdited)
  );
  const saveProduct = useStoreActions(actions => actions.appDock.saveProduct);
  const deleteProduct = useStoreActions(
    actions => actions.appDock.deleteProduct
  );
  const getResourcesWithPrivileges = useStoreState(
    state => state.appDock.getResourcesWithPrivileges
  );

  const uiEditProduct = useStoreActions(
    actions => actions.appDock.uiEditProduct
  );
  const filteredRegionIds = getResourcesWithPrivileges(
    R.map(R.prop("_id"), regions),
    [R.isNil(product) ? Privilege.createProduct : Privilege.editProduct]
  );
  const uiProductIsSaving = useStoreState(state =>
    state.appDock.uiIsProductSaving(uiProductBeingEdited)
  );
  const filteredRegions = R.filter(
    region => R.includes(region._id, filteredRegionIds),
    regions
  );

  const [formValues, setFormValues] = useState({});

  const mergedValues = R.mergeDeepRight(R.defaultTo({}, product), formValues);
  const [validators, isValid] = useValidator(mergedValues);
  const hasChanged = useChangeTracker(product, formValues);

  return (
    <ProductEditPanel
      canDelete={canDeleteProduct}
      canSave={isValid}
      hasChanged={hasChanged}
      isOpen={!!uiProductBeingEdited}
      isSaving={uiProductIsSaving}
      product={product}
      onDismiss={() => {
        uiEditProduct(null);
        setFormValues({});
      }}
      onDelete={async () => {
        await deleteProduct(uiProductBeingEdited);

        uiEditProduct(null);
        setFormValues({});
      }}
      onSave={async () => {
        const product = await saveProduct({
          id: uiProductBeingEdited,
          props: formValues
        });

        if (!R.isNil(product)) {
          uiEditProduct(null);
          setFormValues({});
        }
      }}
    >
      <ProductForm
        disabled={uiProductIsSaving}
        categories={categories}
        regions={filteredRegions}
        product={mergedValues}
        validators={validators}
        onUpdated={values => {
          setFormValues(state => R.mergeDeepRight(state, values));
        }}
      />
    </ProductEditPanel>
  );
};
