import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";

import { Menu } from "./menu";
import { Privilege, ProtectedResource } from "./../../../shared/appdock";

export const MenuContainer = () => {
  const canCreateProduct = useStoreState(state =>
    state.authentication.hasPrivileges(ProtectedResource.appdock, [
      Privilege.createProduct
    ])
  );

  const regions = useStoreState(state => state.appDock.regions);

  const uiRegionFilter = useStoreState(state => state.appDock.uiRegionFilter);
  const uiCreateProduct = useStoreActions(
    actions => actions.appDock.uiCreateProduct
  );

  return (
    <Menu
      regions={regions}
      selectedRegion={uiRegionFilter}
      canCreateProduct={canCreateProduct}
      onCreateProduct={() => {
        uiCreateProduct();
      }}
    />
  );
};
