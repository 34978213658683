import dotenv from "dotenv";
import * as R from "ramda";

export const createEnvironment = () => {
  dotenv.config();

  const nodeEnv = R.defaultTo("development", process.env.NODE_ENV);

  return {
    isDevelopment: /^development$/i.test(nodeEnv),
    isProduction: /^production$/i.test(nodeEnv),
    isTest: /^test$/i.test(nodeEnv),

    /**
     * @param {String} key
     * @param {String} [defaultValue]
     * @returns {String}
     */
    get: (key, defaultValue) => R.propOr(defaultValue, key, process.env)
  };
};
