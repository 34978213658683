import { createAuthenticationModel } from "./model";
import { createAuthenticationService } from "./service";

export const createAuthenticationStore = ({
  authenticationContext,
  getApiClient,
  securityResolvers = []
}) => {
  return {
    model: createAuthenticationModel({securityResolvers}),
    service: createAuthenticationService({
      authenticationContext,
      getApiClient
    })
  };
};
