import { createStore } from "easy-peasy";
import i18next from "i18next";
import { DateTime, Duration } from "luxon";
import * as R from "ramda";
import { adalGetToken } from "react-adal";

import { createAppDockStore } from "./appdock/store";
import { createAuthenticationStore } from "./authentication/store";
import { createOnlineDirectoryStore } from "./online-directory/store";
import { createPlatformStore } from "./platform/store";
import { createApiClient, createHttpClient } from "./shared/net";
import { decodeToken, environment, logger } from "./utils";

export const createAppStore = (authenticationContext) => {
  let store_ = null;
  let apiClient_ = null;

  const createAdalHttpClient_ = async ({
    baseUrl = environment.apiUrl,
    resource = environment.adalClientId,
  } = {}) => {
    const bearerToken = await adalGetToken(
      authenticationContext,
      resource,
      null
    );

    return createHttpClient({ baseUrl, bearerToken });
  };

  const fetchApiToken_ = async () => {
    const httpClient = await createAdalHttpClient_();
    const { data } = await httpClient.post("/api/auth");

    return data;
  };

  const verifyToken_ = (token) => {
    if (!R.isNil(token)) {
      const decodedToken = decodeToken(token);
      const exp = R.path(["payload", "exp"], decodedToken);

      if (!R.isNil(exp)) {
        const expiration = DateTime.fromSeconds(exp, { zone: "utc" })
          .diff(DateTime.utc())
          .minus(Duration.fromObject({ minutes: 2 }))
          .as("milliseconds");

        logger.info(`user token will expire in ${expiration} ms`);
        if (expiration > 0) {
          return true;
        }
      }
    }

    return false;
  };

  const receivedToken_ = (setPermissions) => (token) => {
    const decodedToken = decodeToken(token);
    const permissions = R.pathOr({}, ["payload", "permissions_"], decodedToken);

    setPermissions(permissions);
  };

  const getApiClient = async () => {
    const { setPermissions, setSessionExpired, setServerOffline } =
      store_.getActions().authentication;

    if (!apiClient_) {
      apiClient_ = await createApiClient({
        baseUrl: environment.apiUrl,
        fetchToken: fetchApiToken_,
        verifyToken: verifyToken_,
        receivedToken: receivedToken_(setPermissions),
        noResponse: setServerOffline,
        unauthorized: setSessionExpired,
        logger,
      });
    }

    apiClient_.defaults.headers["Accept-Language"] = i18next.language;

    return apiClient_;
  };

  const authenticationStore = createAuthenticationStore({
    authenticationContext,
    getApiClient,
    securityResolvers: (actions, storeActions) => [
      storeActions.appDock.setProducts,
      storeActions.appDock.addProduct,
      storeActions.appDock.updateProduct,
      storeActions.appDock.setRegions,
    ],
  });

  const platformStore = createPlatformStore();

  const appDockStore = createAppDockStore({
    getApiClient,
    mock: environment.mock,
  });

  const onlineDirectoryStore = createOnlineDirectoryStore({
    getApiClient,
    mock: environment.mock,
  });

  store_ = createStore(
    {
      authentication: authenticationStore.model,
      platform: platformStore.model,
      appDock: appDockStore.model,
      onlineDirectory: onlineDirectoryStore.onlineDirectoryModel,
    },
    {
      injections: {
        authenticationService: authenticationStore.service,
        platformService: platformStore.service,
        appDockService: appDockStore.service,
        onlineDirectoryService: onlineDirectoryStore.onlineDirectoryService,
      },
    }
  );

  return store_;
};
