import { action, actionOn, computed, thunk } from "easy-peasy";
import { DateTime, Duration } from "luxon";
import * as R from "ramda";

import nominatimMapping from "./nominatim-mapping.json";
import { StoreState } from "./../../constant.js";
import { logger } from "./../../utils";

const regions = computed((state) =>
  R.uniqWith(
    (x, y) => R.equals(x.id, y.id),
    R.map(
      (unit) => ({
        id: unit.SosRegionId,
        abbreveation: unit.SosRegion,
      }),
      state.programmes
    )
  )
);

const associations = computed((state) =>
  R.uniqWith(
    (x, y) => R.equals(x.id, y.id),
    R.map(
      (unit) => ({
        id: unit.NationalAssociationId,
        name: unit.NationalAssociation,
        regionId: unit.SosRegionId,
        regionName: unit.SosRegion,
      }),
      R.filter(R.propEq("IsInactive", false), state.programmes)
    )
  )
);

const countryNames = computed((state) =>
  R.map(
    (unit) => ({
      id: unit.id,
      name: R.replace(/^((NA|SA|AC)\s|PA\s(KDEV\s)?)/g, "", unit.name),
      regionId: unit.regionId,
      regionName: unit.regionName,
    }),
    state.associations
  )
);

const allassociations = computed((state) =>
  R.map(
    (unit) => ({
      id: unit.id,
      name: unit.name,
      regionId: unit.regionId,
      regionName: unit.regionName,
    }),
    state.associations
  )
);

const nationalOffices = computed((state) =>
  R.filter(
    (programme) =>
      R.equals(
        programme.OrgEntityUniqueId.substr(
          programme.OrgEntityUniqueId.length - 4
        ),
        programme.NationalAssociationId.substr(
          programme.NationalAssociationId.length - 4
        )
      ),
    state.programmes
  )
);

const unit = computed((state) => state.association || state.region);

const getAssociationsForRegion = computed(
  (state) => (regionId) =>
    R.uniqWith(
      (x, y) => R.equals(x.id, y.id),
      R.map(
        (unit) => ({
          id: unit.id,
          name: unit.name,
          regionId: unit.regionId,
          regionName: unit.regionName,
        }),
        R.filter(R.propEq("regionId", regionId), state.associations)
      )
    )
);

const getProgrammesForAssociation = computed(
  (state) => (id) =>
    R.filter(R.propEq("NationalAssociationId", id), state.programmes)
);

const getNominatimMappingById = computed(
  (state) => (id) => R.find(R.propEq("ID", id), state.nominatimMapping)
);

const getNominatimMappingByCountry = computed(
  (state) => (country) =>
    R.find(R.propEq("Country", country), state.nominatimMapping)
);

const getAllNominatimMappingsByCountry = computed(
  (state) => (country) =>
    R.filter(R.propEq("Country", country), state.nominatimMapping)
);

const getEntityById = computed(
  (state) => (id) =>
    R.find(R.propEq("OrgEntityUniqueId", R.defaultTo("", id)), state.programmes)
);

const getEmbedToken = computed(
  (state) => (reportId) => R.prop(reportId, state.embedToken)
);

const getCurrentUnit = computed((state) => (id) => {
  return state.unit;
});

const setState = action((state, payload) => {
  state.state = payload;
});

const setProgrammes = action((state, payload) => {
  state.programmes = payload;
});

const setRegion = action((state, payload) => {
  if (state.region !== payload) {
    state.region = payload;
  }
});

const setOtherRegion = action((state, payload) => {
  state.otherregion = payload;
});

const setAssociation = action((state, payload) => {
  if (state.association !== payload) {
    state.association = payload;
  }
});
const setEmbedToken = action((state, payload) => {
  const { reportId, embedToken } = payload;
  const { expiration } = embedToken;

  const calcTimeout = () =>
    DateTime.fromISO(expiration)
      .diff(DateTime.utc())
      .minus(Duration.fromObject({ minutes: 2 }))
      .as("milliseconds");

  state.embedToken[reportId] = {
    ...embedToken,
    calcTimeout,
  };
});
const setProgramme = action((state, payload) => {
  if (state.programme !== payload) {
    state.programme = payload;
  }
});
const setReport = action((state, payload) => {
  if (state.report !== payload) {
    state.report = payload;
  }
});
const setAbbreviations = action((state, payload) => {
  state.abbreviations = payload;
});

const setFlyToLatLon = action((state, payload) => {
  if (state.flyToLatLon !== payload) {
    state.flyToLatLon = payload;
  }
});

const setNominatimUrl = action((state, payload) => {
  if (String(payload).toLowerCase().indexOf("nominatim-osm") >= 0) {
    state.nominatimUrl = "https://nominatim.openstreetmap.org";
  }
  if (String(payload).toLowerCase().indexOf("nominatim-sos") >= 0) {
    state.nominatimUrl = "https://nominatim-soscvi.sos-kd.org";
  }
});

const fetchProgrammes = thunk(async (actions, payload, { injections }) => {
  const { onlineDirectoryService } = injections;

  try {
    actions.setState(StoreState.loading);

    const filterNationalOffices = R.filter(
      (entry) =>
        /^NO\s.*|^PA\s.*|^AC\s.*|^PA\s.*/gi.test(
          R.prop("NationalOffice", entry)
        ) &&
        (R.propEq("IsInactive", false, entry) ||
          R.propEq("IsInactive", null, entry)) &&
        R.propEq(
          "NationalOffice",
          R.prop("NationalAssociation", entry)
            .replace("NA", "NO")
            .replace("SA", "NO")
            .replace("PA", "NO"),
          entry
        )
    );
    const [programmes, nationalOffices] = await Promise.all([
      onlineDirectoryService.fetchProgrammes(),
      onlineDirectoryService.fetchNationalOffices(),
    ]);
    const allProgrammes = [
      ...R.filter((p) => R.propEq("SystemStatus", "Released", p), programmes),
      ...filterNationalOffices(nationalOffices),
    ];

    actions.setProgrammes(
      // R.filter(p => R.propEq("SystemStatus", 'Released', p),
      //R.propEq("IsInactive", false, p) || R.propEq("IsInactive", null, p),
      allProgrammes
      // )
    );

    actions.setState(StoreState.loaded);
  } catch (err) {
    logger.error("fetchProgrammes", err);

    actions.setProgrammes([]);

    actions.setState(StoreState.errro);
  }
});

const fetchPowerBIEmbedToken = thunk(
  async (actions, payload, { injections }) => {
    const { groupId, reportId } = payload;
    const { onlineDirectoryService } = injections;

    try {
      const embedToken = await onlineDirectoryService.fetchPowerBIEmbedToken(
        groupId,
        reportId
      );

      actions.setEmbedToken({
        reportId,
        embedToken,
      });
    } catch (err) {
      logger.error("fetchPowerBIEmbedToken", err);
    }
  }
);

const fetchAbbreviations = thunk(async (actions, payload, { injections }) => {
  const { onlineDirectoryService } = injections;

  const abbreveations = await onlineDirectoryService.fetchAbbreviations();
  actions.setAbbreviations(abbreveations);
});

const onSetPermissions = actionOn(
  (actions, storeActions) => storeActions.authentication.setPermissions,
  (state, target) => {
    logger.info("auth state changed, reset embed tokens");
    state.embedToken = {};
  }
);

export const onlineDirectoryModel = {
  // state
  state: StoreState.initial,
  programmes: [],
  region: null,
  otherregion: null,
  association: null,
  programme: null,
  embedToken: {},
  nominatimMapping,
  abbreviations: [],
  report: null,
  flyToLatLon: null,
  nominatimUrl: "https://nominatim-soscvi.sos-kd.org",

  // computed
  regions,
  associations,
  countryNames,
  allassociations,
  nationalOffices,
  unit,
  getEntityById,
  getAssociationsForRegion,
  getProgrammesForAssociation,
  getNominatimMappingById,
  getNominatimMappingByCountry,
  getAllNominatimMappingsByCountry,
  getEmbedToken,
  getCurrentUnit,

  // actions
  setState,
  setProgrammes,
  setRegion,
  setOtherRegion,
  setAssociation,
  setEmbedToken,
  setProgramme,
  setAbbreviations,
  setReport,
  setFlyToLatLon,
  setNominatimUrl,

  // thunks
  fetchProgrammes,
  fetchPowerBIEmbedToken,
  fetchAbbreviations,

  // listeners
  onSetPermissions,
};
