import { CommandBar, FontWeights, getTheme } from "office-ui-fabric-react";
import * as R from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";

import { useModuleNavigateTo } from "./../../../router";
import { locale } from "./../../../shared/appdock";

const theme = getTheme();

const getButtonStyle = selected =>
  selected
    ? {
        root: {
          border: `2px solid ${theme.palette.themePrimary}`,
          borderWidth: "0 0 2px 0"
        },
        label: {
          fontWeight: FontWeights.semibold
        }
      }
    : {};

export const Menu = ({
  regions,
  selectedRegion,
  canCreateProduct,
  onCreateProduct
}) => {
  const { t } = useTranslation(locale.namespace);
  const navigateTo = useModuleNavigateTo();

  const commandBarItems = [
    {
      key: 0,
      text: t(locale.resource.menu.all),
      title: t(locale.resource.menu.allTooltip),
      iconProps: {
        iconName: "Globe"
      },
      buttonStyles: getButtonStyle(R.isNil(selectedRegion)),
      onClick: () => {
        navigateTo();
      }
    },
    ...R.map(
      region => ({
        key: `${region._id}`,
        text: region.abbreviation,
        title: region.name,
        iconProps: {
          iconName: "Flag"
        },
        buttonStyles: getButtonStyle(R.equals(region._id, selectedRegion)),
        onClick: () => {
          navigateTo(`/${R.toLower(region.abbreviation)}`);
        }
      }),
      regions
    )
  ];

  const commandBarAdminItems = [];
  if (canCreateProduct) {
    commandBarAdminItems.push({
      key: "add",
      name: t(locale.resource.admin.addAnApp),
      iconProps: {
        iconName: "Add"
      },
      onClick: () => {
        onCreateProduct();
      }
    });
  }

  return <CommandBar items={commandBarItems} farItems={commandBarAdminItems} />;
};
