export const createAppDockService = ({ getApiClient }) => {
  const getRegions = async () => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get("/api/appdock/regions");

    return data;
  };

  const getCategories = async () => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get("/api/appdock/categories");

    return data;
  };

  const getProducts = async () => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get("/api/appdock/products");

    return data;
  };

  const getProduct = async id => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get(`/api/appdock/products/${id}`);

    return data;
  };

  const createProduct = async props => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.post(`/api/appdock/products`, props);

    return data;
  };

  const updateProduct = async (id, props) => {
    const apiClient = await getApiClient();

    await apiClient.patch(`/api/appdock/products/${id}`, props);
  };

  const deleteProduct = async id => {
    const apiClient = await getApiClient();

    await apiClient.delete(`/api/appdock/products/${id}`);
  };

  const getProductsLog = async region => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get(
      `/api/appdock/log/products?region=${region}`
    );

    return data;
  };

  const getProductLog = async (product, limit = 4) => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get(
      `/api/appdock/log/products?product=${product}&limit=${limit}`
    );

    return data;
  };

  return {
    getCategories,
    getProducts,
    getProduct,
    getRegions,
    createProduct,
    updateProduct,
    deleteProduct,
    getProductsLog,
    getProductLog
  };
};
