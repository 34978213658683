
import * as R from "ramda";

export const decodeToken = token => {
  if (R.is(String, token)) {
    const parts = token.split(".");
    if (parts.length === 3) {
      const processString = R.pipe(R.replace(/-/g, "+"), R.replace(/_/g, "/"));

      try {
        const headerRaw = processString(parts[0]);
        const header = JSON.parse(atob(headerRaw));

        const payloadRaw = processString(parts[1]);
        const payload = JSON.parse(atob(payloadRaw));

        return {
          header,
          payload
        };
      } catch (error) {}
    }
  }

  return null;
};
