import { useCallback, useEffect } from "react";
import { useNavigateTo } from "./use-navigate-to";

export const useRouter = () => {
  const navigateTo = useNavigateTo();

  const onHashChange = useCallback(
    e => {
      if (e.newUrl !== e.oldUrl) {
        navigateTo(e.newUrl);
      }
    },
    [navigateTo]
  );

  useEffect(() => {
    window.addEventListener("hashchange", onHashChange);

    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, [onHashChange]);
};
