import { useStoreActions, useStoreState } from "easy-peasy";
import * as R from "ramda";
import React from "react";

import { ProductDetails } from "./product-details";
import { Privilege, ProtectedResource } from "./../../../shared/appdock";

export const ProductDetailsContainer = ({ category, product }) => {
  const { _id: productId } = product;

  const canEditProduct = useStoreState(state =>
    state.authentication.hasPrivileges(ProtectedResource.appdock, [
      Privilege.editProduct
    ])
  );

  const saveProduct = useStoreActions(actions => actions.appDock.saveProduct);

  const uiProduct = useStoreState(state => state.appDock.uiProduct(productId));
  const uiProductIsSaving = useStoreState(state =>
    state.appDock.uiIsProductSaving(productId)
  );
  const uiEditProduct = useStoreActions(
    actions => actions.appDock.uiEditProduct
  );

  return (
    <ProductDetails
      category={category}
      product={product}
      canEdit={canEditProduct}
      isSaving={uiProductIsSaving}
      isUpdatingStatus={R.hasPath(["modified", "status"], uiProduct)}
      onChangeProductStatus={status => {
        saveProduct({
          id: productId,
          props: {
            status
          }
        });
      }}
      onEditProduct={() => {
        uiEditProduct(productId);
      }}
    />
  );
};
