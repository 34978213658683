import * as R from "ramda";

/**
 * @function
 * @param {String[]|Object} keys
 * @param {{
 *  namespace?: String,
 *  namespaceSeperator?: String
 *  pathSeperator?: String
 *  transform?: "camelCase" | false
 * }} options
 */
export const createStringEnum = (
  keys,
  {
    namespace = null,
    namespaceSeperator = ".",
    pathSeperator = "-",
    transform = "camelCase"
  } = {}
) => {
  const prefix = namespace ? `${namespace}${namespaceSeperator}` : "";

  const toCamelCase = R.replace(/[A-Z]/g, upperCaseLetter =>
    R.concat(pathSeperator, R.toLower(upperCaseLetter))
  );

  const transformValue = value => {
    if (transform === "camelCase") {
      return toCamelCase(value);
    }

    return value;
  };

  return Object.freeze(
    R.mergeAll(
      R.uniq(
        R.map(
          key => {
            if (R.is(Object, keys) && R.is(Object, keys[key])) {
              return {
                [key]: createStringEnum(keys[key], {
                  namespace: R.concat(prefix, transformValue(key)),
                  namespaceSeperator: pathSeperator,
                  pathSeperator,
                  transform
                })
              };
            } else {
              const value = R.length(keys[key]) ? keys[key] : key;

              return {
                [key]: R.concat(prefix, transformValue(value))
              };
            }
          },
          R.is(Array, keys) ? keys : R.keys(keys)
        )
      )
    )
  );
};
