import "office-ui-fabric-react/dist/css/fabric.css";

import { graph } from "@pnp/graph";
import { FluentCustomizations } from "@uifabric/fluent-theme";
import { initializeIcons } from "@uifabric/icons";
import { StoreProvider } from "easy-peasy";
import i18next from "i18next";
import i18backend from "i18next-fetch-backend";
import { Customizer, Fabric, mergeStyles } from "office-ui-fabric-react";
import React from "react";
import { adalGetToken, runWithAdal } from "react-adal";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";

import { App } from "./app";
import * as serviceWorker from "./serviceWorker";
import { createAppStore } from "./store";
import { environment, createAdalAuthenticationContext } from "./utils";

const authenticationContext = createAdalAuthenticationContext();

graph.setup({
  graph: {
    fetchClientFactory: () => ({
      fetch: async (url, options) => {
        const bearerToken = await adalGetToken(
          authenticationContext,
          "https://graph.microsoft.com"
        );
        options.headers.set("Authorization", `Bearer ${bearerToken}`);

        return fetch(url, options);
      }
    })
  }
});

runWithAdal(
  authenticationContext,
  async () => {
    await i18next
      .use(i18backend)
      .use(initReactI18next)
      .init({
        defaultNS: "common",
        fallbackLng: false,
        lng: "en",
        interpolation: { escapeValue: false },
        ns: ["common", "appdock", "online-directory"],
        saveMissing: true,
        whitelist: ["en", "de"],

        backend: {
          loadPath: `${environment.apiUrl}/locales/{{lng}}/{{ns}}`,
          addPath: `${environment.apiUrl}/locales/add/{{lng}}/{{ns}}`,

          requestOptions: {
            headers: {
              "Content-Type": "application/json"
            }
          }
        }
      });

    initializeIcons();

    mergeStyles({
      selectors: {
        ":global(body), :global(html), :global(#root)": {
          margin: 0,
          padding: 0,
          width: "100vw",
          height: "100vh",
          overflow: "hidden"
        }
      }
    });

    ReactDOM.render(
      <Fabric>
        <Customizer {...FluentCustomizations}>
          <StoreProvider store={createAppStore(authenticationContext)}>
            <App />
          </StoreProvider>
        </Customizer>
      </Fabric>,
      document.getElementById("root")
    );
  },
  false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
