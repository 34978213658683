const logFunction = (message, ...args) => {};

export const nullLogger = {
  trace: logFunction,
  debug: logFunction,
  info: logFunction,
  warn: logFunction,
  error: logFunction,
  fatal: logFunction
};
