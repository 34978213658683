import { DateTime } from "luxon";
import { Stack, Text, FontWeights } from "office-ui-fabric-react";
import * as R from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";

import { getProductStatusColor } from "./styles";
import { locale, EventType, ResourceType } from "./../../../shared/appdock";

const Status = ({ status }) => {
  const { t } = useTranslation(locale.namespace);

  return (
    <Text styles={{ root: { color: getProductStatusColor(status) } }}>
      {t(R.prop(status, locale.resource.product.status))}
    </Text>
  );
};

const StatusUpdate = ({ from, to, timestamp }) => (
  <div>
    <Text styles={{ root: { fontWeight: FontWeights.semibold } }}>
      [{DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)}]
    </Text>{" "}
    status changed from "<Status status={from} />" to "<Status status={to} />"
  </div>
);

export const ProductLog = ({ entries }) => {
  return (
    <Stack horizontalAlign="stretch">
      {R.map(
        item =>
          item.resourceType === ResourceType.product &&
          item.name === EventType.product.statusChanged && (
            <Stack.Item key={item._id}>
              <StatusUpdate
                {...JSON.parse(item.payload)}
                timestamp={item.createdAt}
              />
            </Stack.Item>
          ),
        R.slice(0, 4, entries)
      )}
    </Stack>
  );
};
