import { action, computed, thunk } from "easy-peasy";
import * as R from "ramda";
import { v4 as uuidv4 } from "uuid";

import { StoreState } from "./../../constant";
import { logger } from "./../../utils";

const uiProductProps = {
  editing: false,
  saving: false,
  modified: {},
};

const nameToLower_ = R.pipe(R.prop("name"), R.toLower);

const statusToSortOrder_ = R.pipe(R.prop("status"), R.toLower, (status) => {
  switch (status) {
    case "down":
      return -3;
    case "maintenance":
      return -2;
    case "up":
      return -1;
    default:
      return 0;
  }
});

const getElementById_ = (id, list) =>
  id ? R.find(R.propEq("_id", id), R.defaultTo([], list)) : null;

const getElementIndex_ = (id, list) =>
  id ? R.findIndex(R.propEq("_id", id), R.defaultTo([], list)) : null;

const getProperty_ = (key, object) =>
  key ? R.prop(key, R.defaultTo({}, object)) : null;

const productsForActiveRegion = computed((state) => {
  const regionFilter = R.prop("regionFilter", state.ui);

  if (regionFilter) {
    return R.filter(
      (product) => R.find(R.propEq("_id", regionFilter), product.regions),
      state.products
    );
  }

  return state.products;
});

const productsForCategory = computed((state) => (id) => {
  const products = R.defaultTo([], state.productsForActiveRegion);

  return R.sortWith(
    [R.ascend(statusToSortOrder_), R.ascend(nameToLower_)],
    R.filter(
      (product) => R.find(R.propEq("_id", id), product.categories),
      products
    )
  );
});

const categoriesByProduct = computed((state) => {
  const products = R.defaultTo([], state.productsForActiveRegion);
  const categories = R.pipe(
    R.map((product) => product.categories),
    R.flatten,
    R.uniqWith((x, y) => R.equals(x._id, y._id))
  )(products);

  return R.sortWith([R.ascend(nameToLower_)], categories);
});

const getResourcesWithPrivileges = computed(
  [(state, storeState) => storeState.authentication.hasPrivileges],
  (hasPrivileges) => (resources, privileges) =>
    R.filter((resource) => hasPrivileges(resource, privileges), resources)
);

const getRegionByAbbreviation = computed((state) => (abbreviation) =>
  R.find(
    (region) =>
      R.test(new RegExp(`^${region.abbreviation}$`, "gi"), abbreviation),
    state.regions
  )
);

const getProductById = computed((state) => (id) =>
  getElementById_(id, state.products)
);

const getProductLog = computed((state) => (id) =>
  R.defaultTo([], getProperty_(id, state.productLog))
);

const uiRegionFilter = computed((state) => R.prop("regionFilter", state.ui));

const uiProduct = computed((state) => (id) =>
  getProperty_(id, state.uiProducts)
);

const uiProductBeingEdited = computed((state) =>
  R.find(
    (key) => R.propEq("editing", true, state.uiProducts[key]),
    R.keys(R.defaultTo({}, state.uiProducts))
  )
);

const uiIsProductSaving = computed((state) => (id) => {
  const uiProduct = getProperty_(id, state.uiProducts);

  if (uiProduct) {
    return uiProduct.saving;
  }

  return false;
});

const fetch = thunk(async (actions, payload, { injections }) => {
  const { appDockService } = injections;

  try {
    actions.setState(StoreState.loading);

    const [regions, categories, products] = await Promise.all([
      appDockService.getRegions(),
      appDockService.getCategories(),
      appDockService.getProducts(),
    ]);

    actions.setRegions(regions);
    actions.setCategories(categories);
    actions.setProducts(products);

    actions.setState(StoreState.loaded);
  } catch (err) {
    logger.error("fetch", err);

    actions.setRegions([]);
    actions.setCategories([]);
    actions.setProducts([]);

    actions.setState(StoreState.error);
  }
});

const fetchLog = thunk(async (actions, payload, { injections }) => {
  const { appDockService } = injections;

  try {
    const log = await appDockService.getProductsLog(payload);

    actions.setLog(log);
  } catch (err) {
    logger.error("fetchLog", err);

    actions.setLog([]);
  }
});

const fetchProductLog = thunk(async (actions, payload, { injections }) => {
  const { appDockService } = injections;

  try {
    const log = await appDockService.getProductLog(payload);

    actions.setProductLog({ id: payload, log });
  } catch (err) {
    logger.error("fetchProductLog", err);

    actions.setProductLog({ id: payload, log: [] });
  }
});

const saveProduct = thunk(
  async (actions, payload, { getState, injections }) => {
    const { id, props } = payload;
    const { appDockService } = injections;

    let result = null;

    try {
      actions.uiUpdateProduct({ id, props: { saving: true, modified: props } });

      let product = getElementById_(id, getState().products);
      if (product) {
        await appDockService.updateProduct(id, props);

        actions.updateProduct(payload);

        result = getElementById_(id, getState().products);
      } else {
        product = await appDockService.createProduct(props);

        actions.addProduct(product);
        actions.uiRemoveProduct(id);

        result = getElementById_(product._id, getState().products);
      }
    } catch (err) {
      logger.error("saveProduct", err);
    } finally {
      actions.uiUpdateProduct({ id, props: { saving: false, modified: {} } });
    }

    return result;
  }
);

const deleteProduct = thunk(async (actions, payload, { injections }) => {
  const { appDockService } = injections;

  try {
    actions.uiUpdateProduct({ id: payload, props: { saving: true } });

    await appDockService.deleteProduct(payload);

    actions.removeProduct(payload);
    actions.uiRemoveProduct(payload);
  } catch (err) {
    logger.error("deleteProduct", err);
  } finally {
    actions.uiUpdateProduct({ id: payload, props: { saving: false } });
  }
});

const uiCreateProduct = thunk((actions, payload) => {
  const tempId = uuidv4();

  actions.uiAddProduct(tempId);
  actions.uiEditProduct(tempId);
});

const onProductCreated = thunk(async (actions, payload, { injections }) => {
  const { resource } = payload;
  const { appDockService } = injections;

  const product = await appDockService.getProduct(resource);

  actions.addProduct(product);
  actions.addProductLog(payload);
});

const onProductDeleted = thunk(async (actions, payload) => {
  const { resource } = payload;

  actions.removeProduct(resource);
  actions.uiRemoveProduct(resource);
});

const onProductStatusChanged = thunk((actions, payload) => {
  const { resource, payload: eventPayloadRaw } = payload;

  const eventPayload = JSON.parse(eventPayloadRaw);

  actions.updateProduct({
    id: resource,
    props: {
      status: eventPayload.to,
    },
  });

  actions.addProductLog(payload);
});

const onProductUpdated = thunk(async (actions, payload, { injections }) => {
  const { resource } = payload;
  const { appDockService } = injections;

  const product = await appDockService.getProduct(resource);

  actions.updateProduct({
    id: resource,
    props: product,
  });

  actions.addProductLog(payload);
});

const setState = action((state, payload) => {
  state.state = payload;
});

const setRegions = action((state, payload) => {
  state.regions = payload;
});

const setCategories = action((state, payload) => {
  state.categories = payload;
});

const setProducts = action((state, payload) => {
  state.products = payload;

  state.uiProducts = R.zipObj(
    R.map(R.prop("_id"), payload),
    R.repeat(uiProductProps, payload.length)
  );
});

const addProduct = action((state, payload) => {
  const { _id: id } = payload;

  const product = getElementById_(id, state.products);
  if (product) {
    const idx = getElementIndex_(id, state.products);
    state.products[idx] = payload;
  } else {
    state.products.push(payload);
  }

  state.uiProducts[id] = uiProductProps;
});

const updateProduct = action((state, payload) => {
  const { id, props } = payload;

  const product = getElementById_(id, state.products);
  const idx = getElementIndex_(id, state.products);
  state.products[idx] = R.mergeDeepRight(product, props);
});

const removeProduct = action((state, payload) => {
  state.products = R.filter(
    R.pipe(R.propEq("_id", payload), R.not),
    state.products
  );
});

const addProductLog = action((state, payload) => {
  const { resource } = payload;

  const productLog = getProperty_(resource, state.productLog);
  if (productLog) {
    state.productLog[resource] = [payload, ...state.productLog[resource]];
  } else {
    state.productLog[resource] = [payload];
  }
});

const setProductLog = action((state, payload) => {
  const { id, log } = payload;

  state.productLog[id] = log;
});

const uiSetRegionFilter = action((state, payload) => {
  state.ui.regionFilter = payload;
});

const uiAddProduct = action((state, payload) => {
  state.uiProducts[payload] = {
    editing: false,
    temporary: true,
  };
});

const uiEditProduct = action((state, payload) => {
  R.forEach((product) => {
    product.editing = false;
  }, R.values(state.uiProducts));

  if (payload) {
    const uiProduct = getProperty_(payload, state.uiProducts);
    uiProduct.editing = true;
  }
});

const uiRemoveProduct = action((state, payload) => {
  delete state.uiProducts[payload];
});

const uiUpdateProduct = action((state, payload) => {
  const { id, props } = payload;

  state.uiProducts[id] = R.mergeRight(state.uiProducts[id], props);
});

export const appDockModel = {
  // state
  state: StoreState.initial,
  regions: [],
  categories: [],
  products: [],
  log: [],
  productLog: {},

  ui: {},
  uiProducts: {},

  // computed
  productsForActiveRegion,
  productsForCategory,
  categoriesByProduct,
  getResourcesWithPrivileges,
  getRegionByAbbreviation,
  getProductById,
  getProductLog,

  uiRegionFilter,
  uiProduct,
  uiProductBeingEdited,
  uiIsProductSaving,

  // thunks
  fetch,
  fetchLog,
  fetchProductLog,
  saveProduct,
  deleteProduct,
  uiCreateProduct,

  onProductCreated,
  onProductDeleted,
  onProductStatusChanged,
  onProductUpdated,

  // actions
  setState,
  setRegions,
  setCategories,
  addProduct,
  setProducts,
  addProductLog,
  updateProduct,
  removeProduct,
  setProductLog,

  uiSetRegionFilter,
  uiAddProduct,
  uiEditProduct,
  uiRemoveProduct,
  uiUpdateProduct,
};
