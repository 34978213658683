import { action, thunk, computed } from "easy-peasy";
import * as R from "ramda";

export const createPlatformModel = () => {
  const defaultModule = computed(state =>
    R.find(R.propEq("default", true), state.modules)
  );

  const getModuleById = computed(state => id =>
    R.find(R.propEq("id", id), state.modules)
  );

  const setActiveModule = action((state, payload) => {
    state.activeModule = payload;
  });

  const setModules = action((state, payload) => {
    state.modules = payload;
  });

  const uiSetShowMenu = action((state, payload) => {
    state.ui.showMenu = payload;
  });

  const uiSetShowProfile = action((state, payload) => {
    state.ui.showProfile = payload;
  });

  const fetchModules = thunk(async (actions, payload, { injections }) => {
    const { platformService } = injections;

    const modules = await platformService.fetchModules(payload);

    actions.setModules(modules);
  });

  return {
    // state
    modules: [],
    defaultModule,
    activeModule: null,

    ui: {
      showMenu: false,
      showProfile: false
    },

    // computed
    getModuleById,

    // actions
    setActiveModule,
    setModules,

    uiSetShowMenu,
    uiSetShowProfile,

    // thunks
    fetchModules
  };
};
