import {
  Callout,
  DirectionalHint,
  FontWeights,
  FontSizes,
  Icon,
  IconButton,
  Link,
  Persona,
  PersonaSize,
  Panel,
  PanelType,
  Stack,
  Text,
  getTheme,
  mergeStyleSets
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { locale } from "./../../../shared/common";

// TODO: WORKAROUND for light dismiss
import { PanelBase } from "office-ui-fabric-react/lib/components/Panel/Panel.base";
import { elementContains } from "office-ui-fabric-react/lib/Utilities";

PanelBase.prototype._shouldListenForOuterClick = props => {
  return !props.isBlocking && !!props.isOpen;
};

PanelBase.prototype._dismissOnOuterClick = function(ev) {
  const panel = this._panel.current;
  if (!R.isNil(panel) && R.equals(this.state.visibility, 2)) {
    if (!elementContains(panel, ev.target)) {
      if (this.props.onOuterClick && !this.props.onOuterClick()) {
        ev.preventDefault();
      } else {
        this.dismiss();
      }
    }
  }
};
//

const theme = getTheme();

const styles = mergeStyleSets({
  titleBar: {
    backgroundColor: theme.palette.neutralDark,
    color: theme.palette.white,
    height: "50px",
    lineHeight: "48px"
  },
  activeModule: {
    margin: "0px",
    padding: "0px"
  },
  photo: {
    cursor: "pointer",
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingRight: theme.spacing.m,
    userSelect: "none"
  },
  menu: {
    color: theme.palette.black,
    fontWeight: FontWeights.regular
  },
  menuHeader: {
    height: "50px",
    lineHeight: "48px"
  },
  moduleIcon: {
    width: "34px",
    height: "100%",
    fontSize: "26px"
  },
  module: {
    height: "50px",
    lineHeight: "48px",
    paddingLeft: theme.spacing.m,
    paddingRight: theme.spacing.m,
    cursor: "pointer",
    userSelect: "none",
    selectors: {
      ":hover": {
        boxShadow: theme.effects.elevation16
      }
    }
  },
  profile: {
    backgroundColor: theme.palette.white,
    right: "0px !important",
    minWidth: "300px"
  }
});

const getWaffleStyle = (active = false) =>
  mergeStyleSets({
    icon: {
      ...theme.fonts.mediumPlus,
      fontWeight: FontWeights.semibold,
      width: "48px",
      height: "100%",
      backgroundColor: active ? theme.palette.white : theme.palette.neutralDark,
      color: active ? theme.palette.black : theme.palette.white,
      selectors: {
        ":active": {
          backgroundColor: active ? theme.palette.white : theme.palette.black,
          color: active ? theme.palette.black : theme.palette.white
        },
        ":hover": {
          backgroundColor: active
            ? theme.palette.neutralLight
            : theme.palette.black,
          color: active ? theme.palette.black : theme.palette.white
        }
      }
    }
  });

export const Menu = ({
  modules,
  activeModule,
  user,
  showMenu,
  showProfile,
  onShowMenu,
  onDismissMenu,
  onShowProfile,
  onDismissProfile,
  onSelectModule,
  onSignOut
}) => {
  const { t } = useTranslation([locale.namespace]);

  const profileRef = useRef(null);

  return (
    <React.Fragment>
      <Stack className={styles.titleBar} horizontal verticalAlign="center">
        <Stack.Item verticalFill>
          <IconButton
            className={getWaffleStyle().icon}
            iconProps={{ iconName: "WaffleOffice365" }}
            onClick={() => {
              onShowMenu();
            }}
          />
        </Stack.Item>
        <Stack.Item grow>
          <Text
            variant="mediumPlus"
            style={{
              cursor: "pointer",
              fontWeight: FontWeights.semibold,
              userSelect: "none"
            }}
            onClick={() => {
              onSelectModule(activeModule);
            }}
          >
            {R.propOr("", "name", activeModule)}
          </Text>
        </Stack.Item>
        <Stack.Item verticalFill>
          <div ref={profileRef} className={styles.photo}>
            <Persona
              hidePersonaDetails
              imageUrl={R.pathOr(null, ["photo"], user)}
              size={PersonaSize.size32}
              onClick={() => {
                showProfile ? onDismissProfile() : onShowProfile();
              }}
            />
          </div>
        </Stack.Item>
      </Stack>
      <Panel
        className={styles.menu}
        isBlocking={false}
        isLightDismiss
        isOpen={showMenu}
        type={PanelType.smallFixedNear}
        onDismiss={() => {
          onDismissMenu();
        }}
        onRenderNavigation={() => (
          <Stack className={styles.menuHeader} horizontal>
            <Stack.Item verticalFill>
              <IconButton
                className={getWaffleStyle(true).icon}
                iconProps={{ iconName: "WaffleOffice365" }}
                onClick={() => {
                  onDismissMenu();
                }}
              />
            </Stack.Item>
          </Stack>
        )}
        onRenderBody={() => (
          <Stack tokens={{ padding: theme.spacing.m }}>
            {R.map(
              module_ => (
                <Stack.Item key={module_.id}>
                  <Stack
                    className={styles.module}
                    horizontal
                    horizontalAlign="fill"
                  >
                    <Stack.Item>
                      <Icon className={styles.moduleIcon} iconName="Product" />
                    </Stack.Item>
                    <Stack.Item grow>
                      <div
                        onClick={() => {
                          onSelectModule(module_);
                        }}
                      >
                        <Text variant="mediumPlus">{module_.name}</Text>
                      </div>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              ),
              modules
            )}
          </Stack>
        )}
      />
      <Callout
        className={styles.profile}
        directionalHint={DirectionalHint.bottomRightEdge}
        hidden={!showProfile}
        isBeakVisible={false}
        target={profileRef.current}
        setInitialFocus={true}
        onDismiss={() => {
          onDismissProfile();
        }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.m, padding: theme.spacing.m }}
        >
          <Stack.Item>
            <Persona
              hidePersonaDetails
              imageUrl={R.pathOr(null, ["photo"], user)}
              size={PersonaSize.size100}
              styles={{
                root: {
                  userSelect: "none"
                }
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack vertical verticalFill>
              <Stack.Item>
                <Text
                  variant="mediumPlus"
                  style={{ fontWeight: FontWeights.semibold }}
                >
                  {R.pathOr("", ["profile", "name"], user)}
                </Text>
              </Stack.Item>
              <Stack.Item grow>
                <Text>{R.pathOr("", ["userName"], user)}</Text>
              </Stack.Item>
              <Stack.Item align="end">
                <Link
                  onClick={() => {
                    onSignOut();
                  }}
                >
                  <Stack
                    horizontal
                    style={{ lineHeight: FontSizes.icon }}
                    tokens={{ childrenGap: theme.spacing.s1 }}
                  >
                    <Stack.Item>
                      <Icon iconName="SignOut" />
                    </Stack.Item>
                    <Stack.Item>
                      <Text>{t(locale.resource.menu.signOut)}</Text>
                    </Stack.Item>
                  </Stack>
                </Link>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Callout>
    </React.Fragment>
  );
};
