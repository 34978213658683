import { useStoreActions, useStoreState } from "easy-peasy";
import {
  Stack,
  Spinner,
  SpinnerSize,
  mergeStyleSets,
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { environment } from "./../../utils/environment";
import { locale } from "./../../shared/common";

const styles = mergeStyleSets({
  progress: {
    height: "100%",
  },
});

const Progress = ({ label }) => {
  return (
    <Stack
      className={styles.progress}
      horizontalAlign="center"
      verticalFill
      verticalAlign="center"
    >
      <Stack.Item>
        <Spinner label={label} size={SpinnerSize.large} />
      </Stack.Item>
    </Stack>
  );
};

export const Module = ({ id, loading, loaded, children }) => {
  const { t } = useTranslation(locale.namespace);

  const moduleProps = useStoreState((state) =>
    state.platform.getModuleById(id)
  );
  const setActiveModule = useStoreActions(
    (actions) => actions.platform.setActiveModule
  );

  const trackingPixelUrl = environment.trackingPixelUrl;
  const trackingPixelSuffix = environment.trackingPixelSuffix;
  const user = useStoreState((state) => state.authentication.user);
  const userName = R.propOr(null, "userName", user);

  useEffect(() => {
    document.title = moduleProps.name;
  }, [moduleProps]);

  useEffect(() => {
    if (loaded) {
      setActiveModule(id);
    }
  }, [id, loaded, setActiveModule]);

  if (loading) {
    return (
      <Progress
        label={t(locale.resource.loading.module, { module: moduleProps.name })}
      />
    );
  } else if (loaded) {
    return (
      <React.Fragment>
        {trackingPixelUrl && userName && (
          <img
            src={`https://tool-usage-report-analysis.sos-kd.org/?un=${userName}&tool=${R.join(
              "_",
              [id, trackingPixelSuffix]
            )}`}
            alt=""
            width="0"
            height="0"
          />
        )}
        {children}
      </React.Fragment>
    );
  } else {
    return null;
  }
};
