import React from "react";

import { AppDock } from "./../../appdock";
import { OnlineDirectory } from "./../../online-directory";
import * as appDockLocale from "./../../shared/appdock/localization";
import * as onlineDirectoryLocale from "./../../shared/online-directory/localization";

export const createPlatformService = () => {
  const fetchModules = async t => [
    {
      id: appDockLocale.namespace,
      name: t(
        `${appDockLocale.namespace}:${appDockLocale.resource.module.name}`
      ),
      main: "/appdock",
      render: () => <AppDock />,
      route: "/appdock/:region?",
      default: true
    },
    {
      id: onlineDirectoryLocale.namespace,
      name: t(
        `${onlineDirectoryLocale.namespace}:${onlineDirectoryLocale.resource.module.name}`
      ),
      main: "/onlinedirectory",
      render: () => <OnlineDirectory />,
      route: "/onlinedirectory/:region?/:association?/:programme?/:report?"
    }
  ];

  return {
    fetchModules
  };
};
