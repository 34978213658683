import { Duration } from "luxon";

import { createEnvironment } from "./../shared/utils";

const environment_ = createEnvironment();
const sessionTimeout_ = environment_.get("REACT_APP_SESSION_TIMEOUT");

export const environment = {
  ...environment_,

  mock:
    !environment_.isProduction &&
    /^true$/i.test(environment_.get("REACT_APP_MOCK")),

  apiUrl: environment_.get("REACT_APP_API_URL"),

  adalTenant: environment_.get("REACT_APP_ADAL_TENANT"),
  adalClientId: environment_.get("REACT_APP_ADAL_CLIENT_ID"),

  sessionTimeout: sessionTimeout_
    ? Duration.fromISO(sessionTimeout_).as("milliseconds")
    : Duration.fromObject({ minutes: 20 }).as("milliseconds"),

  trackingPixelUrl: environment_.get("REACT_APP_TRACKINGPIXEL_URL"),
  trackingPixelSuffix: environment_.get("REACT_APP_TRACKINGPIXEL_SUFFIX", ""),
};
