import React, { useEffect } from "react";
import { HashRouter } from "react-router-dom";

import { useNavigateTo, useRouter } from "./../hooks";

export const Router = ({ children }) => {
  const Handler = () => {
    useRouter();

    return <React.Fragment>{children}</React.Fragment>;
  };

  return (
    <HashRouter>
      <Handler />
    </HashRouter>
  );
};

export const Navigate = ({ to }) => {
  const navigateTo = useNavigateTo();

  useEffect(() => {
    navigateTo(to);
  }, [to, navigateTo]);

  return null;
};
