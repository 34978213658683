// import { useStoreActions } from "easy-peasy";
import React, { useEffect } from "react";

// import { View } from "./views";
// import { Module } from "./../platform";
// import { locale } from "./../shared/online-directory";
import { mergeStyleSets, getTheme } from "office-ui-fabric-react";
import { tryCatch } from "ramda";

export const OnlineDirectory = () => {
  // const fetchAbbreviations = useStoreActions(
  //   (actions) => actions.onlineDirectory.fetchAbbreviations
  // );

  // useEffect(() => {
  //   fetchAbbreviations();
  // }, [fetchAbbreviations]);

  // return (
  //   <Module id={locale.namespace} loading={false} loaded={true}>
  //     <View />
  //   </Module>
  // );

  const theme = getTheme();
  const styles = mergeStyleSets({
    leftnavilink: {
      paddingRight: "20px",
      paddingLeft: "20px",
      paddingTop: "100px",
      textAlign: "center",
      fontSize: "20px",
    },
    hrefystyle: {
      color: "#009ae0",
    },
  });

  function trimx(str, ch) {
    var start = 0,
      end = str.length;

    while (start < end && str[start] === ch) ++start;

    while (end > start && str[end - 1] === ch) --end;

    return start > 0 || end < str.length ? str.substring(start, end) : str;
  }

  var newODUrl =
    "https://soscv.sharepoint.com/sites/OnlineDirectory/SitePages/System/App.aspx";
  var redirUrl = newODUrl;
  var translateUrl = "";
  try {
    translateUrl = trimx(
      window.location.toString().split("onlinedirectory/")[1],
      "/"
    )
      .split("/")
      .pop();
    if (translateUrl == "SUM-NAS-RO-210") translateUrl = "LAAM";
    if (translateUrl == "SUM-NAS-RO-110") translateUrl = "ESAF";
    if (translateUrl == "SUM-NAS-RO-130") translateUrl = "WCNA";
    if (translateUrl == "SUM-NAS-RO-610") translateUrl = "EUCM";
    if (translateUrl == "SUM-NAS-RO-310") translateUrl = "ASIA";
    if (translateUrl == "SUM-NAS-RO-510") translateUrl = "EUNA";
  } catch {}

  if (translateUrl != "") redirUrl += "?ODID=" + translateUrl;
  return (
    <div className={styles.leftnavilink}>
      Dear colleague! The old Online Directory (OD) that you just tried to
      access is no longer available.<br></br>
      In March 2023, it was replaced by the new OD that provides a couple of new
      and improved features.<br></br>
      <br></br>
      <b>
        Please move on to the &nbsp;
        <a
          className={styles.hrefystyle}
          href={redirUrl}
          title="Online Directory"
        >
          new Online Directory
        </a>
      </b>
      , update your link(s) and spread the news among colleagues!
      {/* <div>
        <a href={redirUrl}>testlink</a>
      </div> */}
    </div>
  );
};
