import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";

import { useAppDockSocket, useRouterHandler } from "./hooks";
import { View } from "./views";
import { StoreState } from "./../constant";
import { Module } from "./../platform";
import { locale } from "./../shared/appdock";

const EntryPoint = () => {
  useAppDockSocket();
  useRouterHandler();

  return <View />;
};

export const AppDock = () => {
  const state = useStoreState(state => state.appDock.state);
  const fetch = useStoreActions(actions => actions.appDock.fetch);

  const loading = state === StoreState.initial || state === StoreState.loading;
  const loaded = state === StoreState.loaded;

  useEffect(() => {
    if (state === StoreState.initial) {
      fetch();
    }
  }, [state, fetch]);

  return (
    <Module id={locale.namespace} loading={loading} loaded={loaded}>
      <EntryPoint />
    </Module>
  );
};
