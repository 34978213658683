import * as R from "ramda";

import { ProductStatus, ProductUsage, ProductRecommendedBrowser } from "./model";
import { createStringEnum } from "./../utils";

const getKeysOnly = R.pipe(
  R.keys,
  R.map(key => ({ [key]: "" })),
  R.mergeAll
);

const resourceKey = {
  module: {
    name: ""
  },

  admin: {
    addAnApp: ""
  },

  menu: {
    all: "",
    allTooltip: ""
  },

  product: {
    statusGroupHeader: "",
    usageGroupHeader: "",
    contactGroupHeader: "",
    supportingLinkGroupHeader: "",
    roadmapGroupHeader: "",
    bandwithHeader: "",
    recommendedBrowserHeader: "",

    launchButton: "",

    pivot: {
      mainHeader: "",
      logHeader: "",
      requirementsHeader: "",
    },

    status: getKeysOnly(ProductStatus),

    usage: getKeysOnly(ProductUsage),

    recommendedBrowser: getKeysOnly(ProductRecommendedBrowser),

    supportingLink: {
      eLearning: "",
      marketingDocuments: "",
      kpiReport: "",
      releaseNotes: "",
      supportSite: "",
      userManual: ""
    }
  },

  productForm: {
    pivot: {
      baseHeader: "",
      linksHeader: "",
      requirementsHeader: "",
    },

    name: "",
    description: "",
    owner: "",
    url: "",
    category: "",
    region: "",
    status: "",
    usage: "",
    roadmap: "",
    minDownload: "",
    minUpload: "",
    minLatency: "",
    recommendedBrowser: "",

    deleteDialog: {
      title: "",
      body: ""
    }
  }
};

export const namespace = "appdock";

/**
 * @readonly
 * @type {resourceKey}
 */
export const resource = createStringEnum(resourceKey, {
  pathSeperator: ".",
  transform: false
});
