import { useStoreActions, useStoreState } from "easy-peasy";
import {
  Dialog,
  DialogType,
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyleSets
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

import { Menu as PlatformMenu } from "./platform";
import { Navigate, Router } from "./router";
import { locale } from "./shared/common";
import { locale as appDockLocale } from "./shared/appdock";
import { locale as onlineDirectoryLocale } from "./shared/online-directory";

const styles = mergeStyleSets({
  container: {
    width: "100%",
    height: "100vh",
    margin: "0px",
    padding: "0px"
  },
  loadingContainer: {
    height: "100vh"
  }
});

export const App = () => {
  const { t } = useTranslation([
    locale.namespace,
    appDockLocale.namespace,
    onlineDirectoryLocale.namespace
  ]);

  const serverOffline = useStoreState(
    state => state.authentication.serverOffline
  );
  const user = useStoreState(state => state.authentication.user);
  const login = useStoreActions(actions => actions.authentication.login);

  const fetchModules = useStoreActions(
    actions => actions.platform.fetchModules
  );
  const modules = useStoreState(state => state.platform.modules);
  const defaultModule = useStoreState(state => state.platform.defaultModule);

  const routes = useMemo(
    () =>
      R.map(
        m => <Route key={m.id} path={m.route} render={() => m.render()} />,
        modules
      ),
    [modules]
  );

  useEffect(() => {
    login();
  }, [login]);

  useEffect(() => {
    fetchModules(t);
  }, [t, fetchModules]);

  const OfflineDialog = () =>
    serverOffline && (
      <Dialog
        hidden={false}
        dialogContentProps={{
          type: DialogType.normal,
          title: t(locale.resource.dialog.offline.title),
          subText: t(locale.resource.dialog.offline.text)
        }}
        modalProps={{ isBlocking: true, isDarkOverlay: true }}
      />
    );

  return (
    <React.Fragment>
      <OfflineDialog />
      {R.isNil(user) && R.isEmpty(modules) ? (
        <Stack
          className={styles.loadingContainer}
          horizontalAlign="center"
          verticalFill
          verticalAlign="center"
        >
          <Stack.Item>
            <Spinner
              label={t(locale.resource.loading.user)}
              size={SpinnerSize.large}
            />
          </Stack.Item>
        </Stack>
      ) : (
          <Router>
            <Stack className={styles.container}>
              <Stack.Item>
                <PlatformMenu />
              </Stack.Item>
              <Stack.Item verticalFill>
                <Switch>
                  {routes}
                  <Navigate to={defaultModule.main} />
                </Switch>
              </Stack.Item>
            </Stack>
          </Router>
        )}
    </React.Fragment>
  );
};
