import { createStringEnum } from "./../utils";

const resourceKey = {
  button: {
    cancel: "",
    delete: "",
    refresh: "",
    save: ""
  },

  dialog: {
    offline: {
      title: "",
      text: ""
    },
    sessionExpired: {
      title: "",
      text: ""
    }
  },

  loading: {
    module: "",
    user: ""
  },

  menu: {
    signOut: ""
  }
};

export const namespace = "common";

/**
 * @readonly
 * @type {resourceKey}
 */
export const resource = createStringEnum(resourceKey, {
  pathSeperator: ".",
  transform: false
});
