import { useStoreState } from "easy-peasy";
import React from "react";

import { CategoryCard } from "./card";

export const CategoryCardContainer = props => {
  const { _id: id } = props;

  const products = useStoreState(state =>
    state.appDock.productsForCategory(id)
  );

  return <CategoryCard category={props} products={products} />;
};
