import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import io from "socket.io-client";

import { EventType } from "./../../shared/appdock";
import { environment, logger } from "./../../utils";

export const useAppDockSocket = () => {
  const onProductCreated = useStoreActions(
    actions => actions.appDock.onProductCreated
  );
  const onProductDeleted = useStoreActions(
    actions => actions.appDock.onProductDeleted
  );
  const onProductStatusChanged = useStoreActions(
    actions => actions.appDock.onProductStatusChanged
  );
  const onProductUpdated = useStoreActions(
    actions => actions.appDock.onProductUpdated
  );

  useEffect(() => {
    const socket = io(`${environment.apiUrl}/appdock`);

    socket.on("connect", () => {
      logger.info(`${environment.apiUrl}/appdock connection established`);
    });
    socket.on("connect_error", error => {
      logger.error(`${environment.apiUrl}/appdock failed to connect`, error);
    });
    socket.on("connect_timeout", error => {
      logger.error(`${environment.apiUrl}/appdock connection timed out`, error);
    });
    socket.on("disconnect", () => {
      logger.info(`${environment.apiUrl}/appdock connection closed`);
    });
    socket.on("error", error => {
      logger.error(`${environment.apiUrl}/appdock unexpected error`, error);
    });

    socket.on(EventType.product.created, event => {
      onProductCreated(event);
    });
    socket.on(EventType.product.deleted, event => {
      onProductDeleted(event);
    });
    socket.on(EventType.product.statusChanged, event => {
      onProductStatusChanged(event);
    });
    socket.on(EventType.product.updated, event => {
      onProductUpdated(event);
    });

    return () => {
      socket.disconnect();
    };
  }, [onProductCreated, onProductDeleted, onProductUpdated, onProductStatusChanged]);
};
