import { createStringEnum } from "./../utils";

const createStringEnumWithNamespace = keys =>
  createStringEnum(keys, { namespace: "appdock" });

const PrivilegeKey = {
  createProduct: "",
  deleteProduct: "",
  editProduct: "",
};

const RoleKey = {
  admin: "",
  regionAdmin: ""
};

const ProductStatusKey = {
  down: "",
  inDevelopment: "",
  maintenance: "",
  phaseIn: "",
  phaseOut: "",
  planned: "",
  up: ""
};

const ProductUsageKey = {
  none: "",
  available: "",
  mandatory: "",
  recommended: ""
};

const ProductRecommendedBrowserKey = {
  chrome: "",
  edge: "",
  chroedge: "",
  internetExplorer: "",
};

const ResourceTypeKey = {
  product: ""
};

const ProductEventKey = {
  created: "",
  deleted: "",
  statusChanged: "",
  updated: ""
};

/**
 * @readonly
 * @type {ProductStatusKey}
 */
export const ProductStatus = createStringEnum(ProductStatusKey, {
  transform: false
});

/**
 * @readonly
 * @type {ProductUsageKey}
 */
export const ProductUsage = createStringEnum(ProductUsageKey, {
  transform: false
});

/**
 * @readonly
 * @type {ProductRecommendedBrowserKey}
 */
export const ProductRecommendedBrowser = createStringEnum(ProductRecommendedBrowserKey, {
  transform: false
});

/**
 * @readonly
 * @type {PrivilegeKey}
 */
export const Privilege = createStringEnumWithNamespace(PrivilegeKey);

/**
 * @readonly
 * @type {RoleKey}
 */
export const Role = createStringEnumWithNamespace(RoleKey);

/**
 * @readonly
 * @type {ResourceTypeKey}
 */
export const ResourceType = createStringEnumWithNamespace(ResourceTypeKey);

export const EventType = Object.freeze({
  /**
   * @readonly
   * @type {ProductEventKey}
   */
  product: createStringEnumWithNamespace(ProductEventKey)
});

/**
 * @readonly
 * @enum {String}
 */
export const ProtectedResource = {
  appdock: "d768d201-8932-4a42-a5cf-8300c7342876"
};
