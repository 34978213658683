import { NeutralColors } from "@uifabric/fluent-theme";
import { graph } from "@pnp/graph";
import Color from "color";
import copy from 'copy-to-clipboard';
import {
  FontSizes,
  FontWeights,
  Icon,
  IconButton,
  Link,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  Text,
  getTheme,
  keyframes,
  mergeStyles,
  mergeStyleSets
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getProductStatusIcon, getProductStatusColor } from "./styles";
import { ProductLogContainer } from "./product-log-container";
import { environment } from "./../../../utils";
import { ProductStatus, ProductUsage, locale } from "./../../../shared/appdock";

const theme = getTheme();

const styles = mergeStyleSets({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "header"
      "body"
    `,
    height: "100%"
  },
  header: {
    gridArea: "header",
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing.l1,
    userSelect: "none"
  },
  body: {
    gridArea: "body",
    padding: theme.spacing.l1
  },
  heading: {
    fontWeight: FontWeights.bold,
    textShadow: `1px 1px 2px ${NeutralColors.gray100}`,
    textTransform: "uppercase"
  },
  groups: {
    marginTop: `-${theme.spacing.l2}`
  },
  group: {
    display: "flex",
    flexFlow: "column",
    marginTop: theme.spacing.l2
  },
  groupHeader: {
    fontSize: FontSizes.mediumPlus,
    lineHeight: FontSizes.mediumPlus,
    userSelect: "none",
    marginBottom: theme.spacing.m
  },
  groupHeaderIcon: {
    marginRight: theme.spacing.s1
  },
  groupHeaderText: {
    textTransform: "uppercase",
    verticalAlign: "top"
  }
});

const spinKeyframes = keyframes({
  from: { transform: "rotateZ(0deg)" },
  to: { transform: "rotateZ(360deg)" }
});

const Group = ({ icon, iconStyle = {}, header, children, onRenderHeader }) => {
  const renderHeader = () => (
    <Text className={styles.groupHeaderText} variant="mediumPlus">
      {header}
    </Text>
  );

  return (
    <div className={styles.group}>
      <div
        className={styles.groupHeader}
        style={R.isNil(children) ? { marginBottom: 0 } : {}}
      >
        {icon && (
          <Icon
            className={mergeStyles(styles.groupHeaderIcon, iconStyle)}
            iconName={icon}
          />
        )}
        {header && R.isNil(onRenderHeader)
          ? renderHeader()
          : onRenderHeader(renderHeader)}
      </div>
      {children}
    </div>
  );
};

const Contact = ({ contact }) => {
  const [profile, setProfile] = useState({
    displayName: R.is(String, contact) ? contact : null,
    mail: null,
    photo: null,
    upn: null
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (R.is(String, contact)) {
        return;
      }

      const displayName = contact.displayName;
      const mail = contact.mail;
      const upn = contact.userPrincipalName;

      let photo = null;
      try {
        const photoBlob = await graph.users.getById(contact.id).photo.getBlob();
        photo = URL.createObjectURL(photoBlob);
      } catch (err) { }

      setProfile({
        displayName,
        mail,
        photo,
        upn
      });
    };

    fetchProfile();
  }, [contact]);

  return R.is(String, contact) ? (
    <Text variant="medium">{contact}</Text>
  ) : (
      <Persona
        text={profile.displayName}
        secondaryText={profile.mail || profile.upn}
        imageUrl={profile.photo}
        imageAlt={profile.displayName}
        size={PersonaSize.size72}
        coinSize={48}
        showUnknownPersonaCoin={R.is(String, contact)}
        onRenderSecondaryText={props => {
          if (R.isNil(props.secondaryText)) {
            return null;
          }

          let profileUrl = `https://cvint-my.sharepoint.com/_layouts/15/me.aspx?p=${props.secondaryText}`;
          if (environment.isProduction) {
            profileUrl = `https://soscv-my.sharepoint.com/_layouts/15/me.aspx?p=${props.secondaryText}`;
          }

          return (
            <Link href={profileUrl} target="_blank">
              {props.secondaryText}
            </Link>
          );
        }}
      />
    );
};

const SupportingLinks = ({ links }) => {
  return (
    <Stack horizontal wrap tokens={{ childrenGap: theme.spacing.m }}>
      {R.map(
        link => (
          <Stack.Item key={link.id}>
            <Link href={link.href} target="_blank">
              {link.text}
            </Link>
          </Stack.Item>
        ),
        R.sortBy(R.prop("id"), links)
      )}
    </Stack>
  );
};

export const ProductDetails = ({
  category,
  product,
  canEdit,
  isSaving,
  isUpdatingStatus,
  onChangeProductStatus,
  onEditProduct
}) => {
  const { links, owner, roadmap, status, url } = product;
  const contact = R.defaultTo(owner, R.prop("owner_", product));
  const usage = R.defaultTo(ProductUsage.none, R.prop("usage", product));
  const requirements = R.defaultTo({}, R.prop("requirements", product));
  const minDownload = R.pathOr(null, ["bandwidth", "minDownload"], requirements);
  const minUpload = R.pathOr(null, ["bandwidth", "minUpload"], requirements);
  const minLatency = R.pathOr(null, ["bandwidth", "minLatency"], requirements);
  const recommendedBrowser = R.propOr(null, "recommendedBrowser", requirements);

  const { t } = useTranslation(locale.namespace);

  const statusMenuItems = R.map(
    key => ({
      key,
      disabled: key === status,
      iconProps: { iconName: getProductStatusIcon(key) },
      text: t(R.prop(key, locale.resource.product.status)),
      onClick: () => {
        onChangeProductStatus(key);
      }
    }),
    R.values(ProductStatus)
  );

  const availableLinks = R.map(
    key => ({
      id: key,
      href: links[key],
      text: t(R.prop(key, locale.resource.product.supportingLink))
    }),
    R.filter(
      key =>
        !R.isNil(links[key]) &&
        !R.isEmpty(links[key]) &&
        !R.equals(key, "supportSite"),
      R.keys(R.defaultTo({}, links))
    )
  );

  const canLaunch = R.cond([
    [R.equals(ProductStatus.up), R.T],
    [R.equals(ProductStatus.phaseIn), R.T],
    [R.equals(ProductStatus.phaseOut), R.T],
    [R.T, R.F]
  ]);

  const coloredButtonStyle = (
    color,
    normalFactor = 0.3,
    hoveredFactor = 0.4,
    pressedFactor = 0.5
  ) => ({
    root: {
      backgroundColor: Color(color).darken(normalFactor),
      borderColor: Color(color).darken(normalFactor)
    },
    rootHovered: {
      backgroundColor: Color(color).darken(hoveredFactor),
      borderColor: Color(color).darken(hoveredFactor)
    },
    rootPressed: {
      backgroundColor: Color(color).darken(pressedFactor),
      borderColor: Color(color).darken(pressedFactor)
    }
  });

  const CallToActionButton = props => (
    <PrimaryButton
      {...props}
      styles={R.mergeDeepRight(coloredButtonStyle(category.color), {
        rootHovered: {
          borderWidth: 0
        },
        rootPressed: {
          borderWidth: 0
        }
      })}
    />
  );

  return (
    <div className={styles.root}>
      <div
        className={mergeStyles(styles.header, {
          color: NeutralColors.white,
          backgroundColor: category.color,
          background: `linear-gradient(45deg, ${category.color} 0%, ${Color(
            category.color
          ).lighten(0.2)} 100%)`
        })}
      >
        <Stack><Stack.Item>
          <Stack
            horizontal
            horizontalAlign="space-between"
            tokens={{ childrenGap: theme.spacing.m }}
          >
            <Stack.Item>
              <Stack>
                <Stack.Item>
                  <Text className={styles.heading} variant="xLarge">
                    {product.name}
                  </Text>
                </Stack.Item>
                <Stack.Item>
                  {R.ifElse(R.isNil, R.always(null), text => <Text>{text}</Text>)(
                    product.description
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              {canEdit && (
                <IconButton
                  iconProps={{ iconName: "Edit" }}
                  style={{
                    color: NeutralColors.white
                  }}
                  styles={R.mergeDeepRight(
                    coloredButtonStyle(category.color, 0, 0.05, 0.1),
                    {
                      root: {
                        backgroundColor: "transparent"
                      },
                      icon: {
                        fontSize: FontSizes.xLarge
                      }
                    }
                  )}
                  onClick={() => {
                    onEditProduct(product._id);
                  }}
                />
              )}
            </Stack.Item>
          </Stack></Stack.Item>
          <Stack.Item>
            <Stack
              horizontal
              tokens={{ childrenGap: theme.spacing.m }}
              styles={{ root: { marginTop: theme.spacing.m } }}
            >
              {url && (
                <Stack.Item>
                  <CallToActionButton
                    disabled={isSaving || !canLaunch(status)}
                    onClick={() => {
                      window.open(url, "_blank");
                    }}
                  >
                    {t(locale.resource.product.launchButton)}
                  </CallToActionButton>
                </Stack.Item>
              )}
              {!R.isEmpty(R.propOr("", "supportSite", links)) && (
                <Stack.Item>
                  <CallToActionButton
                    onClick={() => {
                      window.open(R.prop("supportSite", links), "_blank");
                    }}
                  >
                    {t(locale.resource.product.supportingLink.supportSite)}
                  </CallToActionButton>
                </Stack.Item>
              )}
              {canEdit && (<Stack.Item styles={{ root: { marginLeft: "auto !important" } }}>
                <IconButton
                  iconProps={{ iconName: "Copy" }}
                  style={{
                    color: NeutralColors.white
                  }}
                  styles={R.mergeDeepRight(
                    coloredButtonStyle(category.color, 0, 0.05, 0.1),
                    {
                      root: {
                        backgroundColor: "transparent"
                      },
                      icon: {
                        fontSize: FontSizes.xLarge
                      }
                    }
                  )}
                  onClick={() => {
                    copy(`${window.location.origin}/#/appdock?app=${product._id}`);
                  }}
                />
              </Stack.Item>)}
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
      <Pivot
        styles={{
          root: {
            marginLeft: theme.spacing.m
          }
        }}
      >
        <PivotItem headerText={t(locale.resource.product.pivot.mainHeader)}>
          <div className={styles.body}>
            <div className={styles.groups}>
              <Stack horizontal horizontalAlign="space-between" wrap>
                <Stack.Item>
                  <Group
                    icon="StatusCircleOuter"
                    iconStyle={{ color: getProductStatusColor(status) }}
                    header={`${t(
                      locale.resource.product.statusGroupHeader
                    )}: ${t(R.prop(status, locale.resource.product.status))}`}
                    onRenderHeader={render => (
                      <React.Fragment>
                        {render()}
                        {canEdit && (
                          <IconButton
                            className={styles.spinner}
                            menuProps={{
                              items: statusMenuItems
                            }}
                            menuIconProps={{
                              iconName:
                                isSaving && isUpdatingStatus
                                  ? "ProgressRingDots"
                                  : "Edit"
                            }}
                            style={{
                              marginTop: `-${theme.spacing.s1}`,
                              ...(isSaving && isUpdatingStatus
                                ? {
                                  animation: `${spinKeyframes} 1.5s linear infinite`
                                }
                                : {})
                            }}
                          />
                        )}
                      </React.Fragment>
                    )}
                  />
                </Stack.Item>
                <Stack.Item>
                  {usage !== ProductUsage.none && (
                    <Group
                      icon="Tag"
                      header={`${t(
                        locale.resource.product.usageGroupHeader
                      )}: ${t(R.prop(usage, locale.resource.product.usage))}`}
                    />
                  )}
                </Stack.Item>
              </Stack>
              {contact && (
                <Group
                  icon="Contact"
                  header={t(locale.resource.product.contactGroupHeader)}
                >
                  <Contact contact={contact} />
                </Group>
              )}
              {availableLinks.length > 0 && (
                <Group
                  icon="Link"
                  header={t(locale.resource.product.supportingLinkGroupHeader)}
                >
                  <SupportingLinks links={availableLinks} />
                </Group>
              )}
              {roadmap && (
                <Group
                  icon="RenewalFuture"
                  header={t(locale.resource.product.roadmapGroupHeader)}
                >
                  <Text>
                    <div dangerouslySetInnerHTML={{ __html: roadmap }} />
                  </Text>
                </Group>
              )}
            </div>
          </div>
        </PivotItem>
        <PivotItem headerText={t(locale.resource.product.pivot.logHeader)}>
          <div className={styles.body}>
            <ProductLogContainer id={product._id} />
          </div>
        </PivotItem>
        {R.any(R.pipe(R.isNil, R.not), [minDownload, minUpload, minLatency, recommendedBrowser]) &&
          <PivotItem headerText={t(locale.resource.product.pivot.requirementsHeader)}>
            <div className={styles.body}>
              <div className={styles.groups}>
                {
                  (!R.isNil(minDownload) || !R.isNil(minUpload) || !R.isNil(minLatency)) &&
                  <Group icon="PlugConnected" header={t(locale.resource.product.bandwithHeader)}>
                    {!R.isNil(minDownload) && <Text>Minimum download: {formatBitRate(minDownload)}</Text>}
                    {!R.isNil(minUpload) && <Text>Minimum upload: {formatBitRate(minUpload)}</Text>}
                    {!R.isNil(minLatency) && <Text>Minimum latency: {minLatency} ms</Text>}
                  </Group>}
                {
                  !R.isNil(recommendedBrowser) && !R.isEmpty(recommendedBrowser) &&
                  <Group icon="Globe" header={t(locale.resource.product.recommendedBrowserHeader)}>
                    {R.join(", ", R.map(browser => t(R.prop(browser, locale.resource.product.recommendedBrowser)), recommendedBrowser))}
                  </Group>}
              </div>
            </div>
          </PivotItem>}
      </Pivot>
    </div >
  );
};

const formatBitRate = (input) => {
  const units = ["gbit/s", "mbit/s"];
  const units_limit = [1000000, 1000];

  for (let i = 0; i < units.length; i++) {
    if (input >= units_limit[i]) {
      return `${(input / units_limit[i]).toFixed(2)} ${units[i]}`
    }
  }

  return `${input} kbit/s`;
}
