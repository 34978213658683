import axios from "axios";

export const createHttpClient = ({
  baseUrl = null,
  bearerToken = null,
  contentType = "application/json"
} = {}) =>
  axios.create({
    ...(baseUrl && { baseURL: baseUrl }),
    headers: {
      "Content-Type": contentType,
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` })
    }
  });
