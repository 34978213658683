import { useStoreState } from "easy-peasy";
import * as R from "ramda";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const useNavigateTo = ({ prefix = "" } = {}) => {
  const history = useHistory();

  return useCallback(
    (url = "", addToHistory = true) => {
      const targetUrl = `${prefix}${url}`;

      const currentHash = R.slice(1, Infinity, window.location.hash);
      if (R.equals(targetUrl, currentHash)) {
        return;
      }

      if (addToHistory) {
        history.push(targetUrl);
      }
      history.replace(targetUrl);
    },
    [prefix, history]
  );
};

export const useModuleNavigateTo = () => {
  const activeModuleId = useStoreState(state => state.platform.activeModule);
  const activeModule = useStoreState(state =>
    state.platform.getModuleById(activeModuleId)
  );
  const defaultModule = useStoreState(state => state.platform.defaultModule);
  const currentModule = activeModule || defaultModule;

  return useNavigateTo({ prefix: currentModule.main });
};
