import { graph } from "@pnp/graph";

export const createAuthenticationService = ({
  authenticationContext,
  getApiClient
}) => {
  const login = async () => {
    const apiClient = await getApiClient();

    await apiClient.get("/api/auth");
  };

  const logout = () => {
    authenticationContext.logOut();
  };

  const invalidateSession = () => {
    authenticationContext.clearCache();
  };

  const invalidateToken = async () => {
    const apiClient = await getApiClient();

    apiClient.defaults.headers["Authorization"] = "";
  };

  const fetchUser = async () => {
    return authenticationContext.getCachedUser();
  };

  const fetchPhoto = async () => {
    try {
      const photo = await graph.me.photo.getBlob();

      return URL.createObjectURL(photo);
    } catch (error) {}

    return null;
  };

  return {
    login,
    logout,
    invalidateSession,
    invalidateToken,
    
    fetchUser,
    fetchPhoto
  };
};
