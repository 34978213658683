import * as R from "ramda";

import { createHttpClient } from "./http-client";
import { nullLogger } from "./../utils";

export const createApiClient = async ({
  baseUrl,
  fetchToken,
  verifyToken = token => !R.isNil(token),
  bearerToken = token => `Bearer ${token}`,
  receivedToken = token => {},
  noResponse = () => {},
  unauthorized = () => {},
  unauthorizedStatus = status => status === 401,
  retries = 3,
  cacheToken = true,
  logger = nullLogger
} = {}) => {
  let apiToken_ = null;

  let retryDueToUnauthorized_ = null;
  let retryCount_ = 0;

  const apiClient = createHttpClient({
    baseUrl
  });

  apiClient.interceptors.request.use(async config => {
    if (!verifyToken(apiToken_)) {
      try {
        apiToken_ = await fetchToken();
        receivedToken(apiToken_);

        // update client authentication header
        apiClient.defaults.headers["Authorization"] = bearerToken(apiToken_);

        // update request config
        config.headers["Authorization"] = bearerToken(apiToken_);
      } catch (error) {
        logger.error("apiClient", error);
      }
    }

    return config;
  });

  apiClient.interceptors.response.use(
    response => {
      retryDueToUnauthorized_ = null;
      retryCount_ = 0;

      if (!cacheToken) {
        apiToken_ = null;

        apiClient.defaults.headers = R.omit(
          ["Authorization"],
          apiClient.defaults.headers
        );
      }

      return response;
    },
    async error => {
      logger.error("apiClient", error.message);

      if (error.response) {
        if (unauthorizedStatus(error.response.status)) {
          // unauthorized -> invalidate api token
          // retry the request (token will be fetched by response interceptor)
          if (retryCount_ < retries) {
            apiToken_ = null;

            retryDueToUnauthorized_ = error.config;
            retryCount_++;

            return apiClient.request(retryDueToUnauthorized_);
          }
          // retried but still got an invalid token
          else {
            unauthorized();
          }
        }
      } else {
        noResponse();
      }

      return Promise.reject(error);
    }
  );

  return apiClient;
};
