import { MotionAnimations } from "@uifabric/fluent-theme";
import { mergeStyles, mergeStyleSets, getTheme } from "office-ui-fabric-react";
import React from "react";

import { CategoryDetailsContainer } from "./category-details-container";

const theme = getTheme();

const styles = mergeStyleSets({
  default: {
    flex: "1 0 50%",
    margin: theme.spacing.s1,
    animation: MotionAnimations.fadeIn,
    selectors: {
      "@media(min-width: 768px)": {
        flex: "1 0 40%"
      },
      "@media(min-width: 1200px)": {
        flex: "1 0 25%"
      }
    }
  },
  medium: {
    flex: `1 0 calc(100% - ${theme.spacing.s1} - ${theme.spacing.s1})`,
    selectors: {
      "@media(min-width: 768px)": {
        flex: "1 0 50%"
      },
      "@media(min-width: 1200px)": {
        flex: "1 0 35%"
      }
    }
  },
  large: {
    flex: `1 0 calc(100% - ${theme.spacing.s1} - ${theme.spacing.s1})`,
    selectors: {
      "@media(min-width: 1200px)": {
        flex: "1 0 50%"
      }
    }
  }
});

export const CategoryCard = ({ category, products }) => {
  return (
    <div
      className={mergeStyles(
        styles.default,
        products.length >= 5 ? styles.medium : {},
        products.length >= 10 ? styles.large : {}
      )}
    >
      <CategoryDetailsContainer category={category} products={products} />
    </div>
  );
};
