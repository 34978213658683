import { onlineDirectoryModel } from "./model";
import { createOnlineDirectoryService } from "./service";

export const createOnlineDirectoryStore = ({ getApiClient, mock = false }) => {
  return {
    onlineDirectoryModel,
    onlineDirectoryService: createOnlineDirectoryService({
      getApiClient,
      mock
    })
  };
};
