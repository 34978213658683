import programmes from "./mock/programmes.json";
import nationalOffices from "./mock/national-offices.json";
import abbreviations from "./mock/abbreviations.json";

import { logger } from "./../../utils";

export const createOnlineDirectoryService = ({
  getApiClient,
  mock = false,
}) => {
  const fetchProgrammes = async () => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get("/api/online-directory/programmes");

    return data;
  };

  const fetchNationalOffices = async () => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get(
      "/api/online-directory/national-offices"
    );

    return data;
  };

  const fetchPowerBIEmbedToken = async (groupId, reportId) => {
    const apiClient = await getApiClient();

    const { data } = await apiClient.get(
      `/api/online-directory/powerbi/embed-token/${groupId}/${reportId}`
    );
    return data;
  };

  const fetchAbbreviations = async () => {
    return abbreviations;
  };

  return {
    fetchProgrammes: mock ? () => programmes : fetchProgrammes,
    fetchNationalOffices: mock ? () => nationalOffices : fetchNationalOffices,
    fetchPowerBIEmbedToken,
    fetchAbbreviations,
  };
};
