import { SharedColors } from "@uifabric/fluent-theme";
import * as R from "ramda";

import { ProductStatus } from "./../../../shared/appdock";

export const getProductStatusColor = R.cond([
  [R.equals(ProductStatus.down), R.always(SharedColors.red10)],
  [R.equals(ProductStatus.maintenance), R.always(SharedColors.orange10)],
  [R.equals(ProductStatus.up), R.always(SharedColors.yellowGreen10)],
  [R.T, R.always(SharedColors.gray20)]
]);

export const getProductStatusIcon = R.cond([
  [R.equals(ProductStatus.down), R.always("Cancel")],
  [R.equals(ProductStatus.inDevelopment), R.always("Work")],
  [R.equals(ProductStatus.maintenance), R.always("DeveloperTools")],
  [R.equals(ProductStatus.up), R.always("Running")],
  [R.equals(ProductStatus.planned), R.always("EditNote")],
  [R.equals(ProductStatus.phaseIn), R.always("Installation")],
  [R.equals(ProductStatus.phaseOut), R.always("SignOut")],
  [R.T, R.always("StatusCircleRing")]
]);
