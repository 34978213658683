import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect } from "react";

import { ProductLog } from "./product-log";

export const ProductLogContainer = ({ id }) => {
  const productLog = useStoreState(state => state.appDock.getProductLog(id));

  const fetchProductLog = useStoreActions(
    actions => actions.appDock.fetchProductLog
  );

  useEffect(() => {
    fetchProductLog(id);
  }, [fetchProductLog, id]);

  return <ProductLog entries={productLog} />;
};
