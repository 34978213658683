import { createStringEnum } from "./../utils";

const resourceKey = {
  module: {
    name: ""
  }
};

export const namespace = "online-directory";

/**
 * @readonly
 * @type {resourceKey}
 */
export const resource = createStringEnum(resourceKey, {
  pathSeperator: ".",
  transform: false
});
