import {
  Dropdown,
  Pivot,
  PivotItem,
  Stack,
  TextField,
  getTheme
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { getProductStatusIcon } from "./../category-card/styles";
import { locale, ProductStatus, ProductUsage, ProductRecommendedBrowser } from "./../../../shared/appdock";
import { prop } from "ramda";

const theme = getTheme();

const getStatusOptions = t =>
  R.map(
    key => ({
      key: `${key}`,
      text: t(R.prop(key, locale.resource.product.status)),
      data: { value: key, icon: getProductStatusIcon(key) }
    }),
    R.values(ProductStatus)
  );

const getUsageOptions = t =>
  R.map(
    key => ({
      key: `${key}`,
      text: t(R.prop(key, locale.resource.product.usage)),
      data: { value: key }
    }),
    R.values(ProductUsage)
  );

const getBrowserOption = t =>
  R.map(
    key => ({
      key: `${key}`,
      text: t(R.prop(key, locale.resource.product.recommendedBrowser)),
      data: key
    }),
    R.values(ProductRecommendedBrowser)
  );

export const ProductForm = ({
  disabled,
  categories,
  regions,
  product,
  validators,
  onUpdated
}) => {
  const { t } = useTranslation(locale.namespace);

  const [values, setValues] = useState({
    name: R.propOr("", "name", product),
    description: R.propOr("", "description", product),
    owner: R.propOr("", "owner", product),
    url: R.propOr("", "url", product),
    categories: R.propOr([], "categories", product),
    regions: R.propOr([], "regions", product),
    status: R.propOr(ProductStatus.up, "status", product),
    usage: R.propOr(ProductUsage.none, "usage", product),
    links: {
      userManual: R.pathOr("", ["links", "userManual"], product),
      supportSite: R.pathOr("", ["links", "supportSite"], product),
      marketingDocuments: R.pathOr(
        "",
        ["links", "marketingDocuments"],
        product
      ),
      releaseNotes: R.pathOr("", ["links", "releaseNotes"], product),
      kpiReport: R.pathOr("", ["links", "kpiReport"], product),
      eLearning: R.pathOr("", ["links", "eLearning"], product)
    },
    roadmap: R.propOr("", "roadmap", product),
    requirements: {
      bandwidth: {
        minUpload: R.pathOr("", ["requirements", "bandwidth", "minUpload"], product),
        minDownload: R.pathOr("", ["requirements", "bandwidth", "minDownload"], product),
        minLatency: R.pathOr("", ["requirements", "bandwidth", "minLatency"], product),
      },
      recommendedBrowser: R.pathOr([], ["requirements", "recommendedBrowser"], product),
    }
  });

  const updateValue = (path, value) => {
    const updatedValue = R.assocPath(path, value, {});

    setValues(R.mergeDeepRight(values, updatedValue));

    onUpdated(updatedValue);
  };

  const categoryOptions = R.map(
    category => ({
      key: category._id,
      text: category.name,
      data: category
    }),
    categories
  );

  const regionOptions = R.map(
    region => ({
      key: region._id,
      text: region.name,
      data: region
    }),
    regions
  );

  const renderSupportingLink = (label, path) => (
    <TextField
      disabled={disabled}
      label={label}
      defaultValue={R.path(path, values)}
      onChange={(e, newValue) => {
        updateValue(path, newValue);
      }}
    />
  );

  const updateMultiSelectValue_ = (property, item, filterCallback = null) => {
    const path = R.is(Array, property) ? property : [property];

    const filterCallback_ = R.isNil(filterCallback) ?
      entry => R.not(R.propEq("_id", item.data._id, entry)) : entry => filterCallback(entry, item);

    if (item.selected) {
      updateValue(path, [...R.path(path, values), item.data]);
    } else {
      updateValue(
        path,
        R.filter(filterCallback_, R.path(path, values)
        )
      );
    }
  };

  return (
    <Pivot>
      <PivotItem headerText={t(locale.resource.productForm.pivot.baseHeader)}>
        <Stack
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Stack.Item>
            <TextField
              disabled={disabled}
              errorMessage={R.propEq("name", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.name)}
              required
              value={values.name}
              onChange={(e, newValue) => {
                updateValue(["name"], newValue);
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <TextField
              disabled={disabled}
              label={t(locale.resource.productForm.description)}
              multiline
              value={values.description}
              onChange={(e, newValue) => {
                updateValue(["description"], newValue);
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <TextField
              disabled={disabled}
              label={t(locale.resource.productForm.owner)}
              value={values.owner}
              onChange={(e, newValue) => {
                updateValue(["owner"], newValue);
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <TextField
              disabled={disabled}
              label={t(locale.resource.productForm.url)}
              value={values.url}
              onChange={(e, newValue) => {
                updateValue(["url"], newValue);
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKeys={R.map(R.prop("_id"), values.categories)}
              disabled={disabled}
              errorMessage={R.propEq("categories", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.category)}
              multiSelect
              options={categoryOptions}
              required
              onChange={(e, item) => {
                updateMultiSelectValue_("categories", item);
              }}
            ></Dropdown>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKeys={R.map(R.prop("_id"), values.regions)}
              disabled={disabled}
              errorMessage={R.propEq("regions", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.region)}
              multiSelect
              options={regionOptions}
              required
              onChange={(e, item) => {
                updateMultiSelectValue_("regions", item);
              }}
            ></Dropdown>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKey={values.status}
              disabled={disabled}
              label={t(locale.resource.productForm.status)}
              options={getStatusOptions(t)}
              onChange={(e, newValue) => {
                updateValue(["status"], newValue.data.value);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKey={values.usage}
              disabled={disabled}
              label={t(locale.resource.productForm.usage)}
              options={getUsageOptions(t)}
              onChange={(e, newValue) => {
                updateValue(["usage"], newValue.data.value);
              }}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              disabled={disabled}
              label={t(locale.resource.productForm.roadmap)}
              multiline
              value={values.roadmap}
              onChange={(e, newValue) => {
                updateValue(["roadmap"], newValue);
              }}
            ></TextField>
          </Stack.Item>
        </Stack>
      </PivotItem>
      <PivotItem headerText={t(locale.resource.productForm.pivot.linksHeader)}>
        <Stack
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.userManual),
              ["links", "userManual"]
            )}
          </Stack.Item>
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.supportSite),
              ["links", "supportSite"]
            )}
          </Stack.Item>
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.marketingDocuments),
              ["links", "marketingDocuments"]
            )}
          </Stack.Item>
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.releaseNotes),
              ["links", "releaseNotes"]
            )}
          </Stack.Item>
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.kpiReport),
              ["links", "kpiReport"]
            )}
          </Stack.Item>
          <Stack.Item>
            {renderSupportingLink(
              t(locale.resource.product.supportingLink.eLearning),
              ["links", "eLearning"]
            )}
          </Stack.Item>
        </Stack>
      </PivotItem>
      <PivotItem headerText={t(locale.resource.productForm.pivot.requirementsHeader)}>
        <Stack
          tokens={{
            childrenGap: theme.spacing.m
          }}
        >
          <Stack.Item>
            <TextField
              disabled={disabled}
              errorMessage={R.propEq("minDownload", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.minDownload)}
              value={values.requirements.bandwidth.minDownload}
              onChange={(e, newValue) => {
                updateValue(["requirements", "bandwidth", "minDownload"], R.defaultTo("", Number.parseInt(newValue)));
              }}
            ></TextField>
          </Stack.Item><Stack.Item>
            <TextField
              disabled={disabled}
              errorMessage={R.propEq("minUpload", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.minUpload)}
              value={values.requirements.bandwidth.minUpload}
              onChange={(e, newValue) => {
                updateValue(["requirements", "bandwidth", "minUpload"], R.defaultTo("", Number.parseInt(newValue)));
              }}
            ></TextField></Stack.Item><Stack.Item>
            <TextField
              disabled={disabled}
              errorMessage={R.propEq("minLatency", true, validators) ? "" : " "}
              label={t(locale.resource.productForm.minLatency)}
              value={values.requirements.bandwidth.minLatency}
              onChange={(e, newValue) => {
                updateValue(["requirements", "bandwidth", "minLatency"], R.defaultTo("", Number.parseInt(newValue)));
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <Dropdown
              defaultSelectedKeys={values.requirements.recommendedBrowser}
              disabled={disabled}
              label={t(locale.resource.productForm.recommendedBrowser)}
              multiSelect
              options={getBrowserOption(t)}
              onChange={(e, item) => {
                updateMultiSelectValue_(["requirements", "recommendedBrowser"], item, (key, entry) => key !== entry.key);
              }}
            />
          </Stack.Item>
        </Stack>
      </PivotItem>
    </Pivot>
  );
};
