import * as log4javascript from "log4javascript";

import { environment } from "./environment";

const logger = new log4javascript.getLogger("default");

logger.setLevel(log4javascript.Level.OFF);

if (environment.isDevelopment) {
  logger.setLevel(log4javascript.Level.ALL);
} else if (environment.isProduction) {
  logger.setLevel(log4javascript.Level.INFO);
}

const consoleAppender = new log4javascript.BrowserConsoleAppender();
consoleAppender.setLayout(
  new log4javascript.PatternLayout("[%d] [%p] %c - %m")
);
logger.addAppender(consoleAppender);

export { logger };
