import { action, computed, thunk, thunkOn } from "easy-peasy";
import * as R from "ramda";

import { logger } from "./../../utils";
import { ProtectedResource } from "./../../shared/appdock";

export const createAuthenticationModel = ({ securityResolvers = [] }) => {
  const hasPrivileges = computed(
    [state => R.prop("permissions", state), state => state.resources],
    (permissions, resources) => (resource, privileges) => {
      if (R.isNil(permissions)) {
        return false;
      }

      const hasPrivileges_ = resource_ => {
        const principalPrivileges = R.path(
          ["resources", resource_, "privileges"],
          permissions
        );

        return (
          principalPrivileges &&
          R.all(
            somePrivilege => R.includes(somePrivilege, principalPrivileges),
            privileges
          )
        );
      };

      if (hasPrivileges_(resource)) {
        return true;
      } else {
        const path = R.prop(resource, resources);
        if (path) {
          for (const parent of path) {
            if (hasPrivileges_(parent)) {
              return true;
            }
          }
        }
      }

      return false;
    }
  );

  const login = thunk(async (actions, payload, { injections }) => {
    logger.info("login");

    const { authenticationService } = injections;

    await authenticationService.login();
  });

  const logout = thunk(async (actions, payload, { injections }) => {
    logger.info("logout");

    const { authenticationService } = injections;

    authenticationService.logout();
  });

  const invalidateSession = thunk(async (actions, payload, { injections }) => {
    logger.info("invalidate session");

    const { authenticationService } = injections;

    authenticationService.invalidateSession();
    actions.setSessionExpired();
  });

  const invalidateToken = thunk(async (actions, payload, { injections }) => {
    logger.info("invalidate token");

    const { authenticationService } = injections;

    await authenticationService.invalidateToken();
    actions.setUser(null);
  });

  const onLogin = thunkOn(
    actions => actions.login,
    async (actions, target, { injections }) => {
      const { error } = target;

      if (R.isNil(error)) {
        const { authenticationService } = injections;

        const user = await authenticationService.fetchUser();
        const photo = await authenticationService.fetchPhoto();

        actions.setUser({
          ...user,
          ...(photo && { photo })
        });
      } else {
        logger.error("onLogin", error.message);

        const { response } = error;
        if (response) {
          actions.setSessionExpired();
        } else {
          actions.setServerOffline();
        }
      }
    }
  );

  const onResolveSecurity = thunkOn(securityResolvers, (actions, target) => {
    if (R.is(Array, target.payload)) {
      actions.registerResources(target.payload);
    } else {
      actions.registerResources([target.payload]);
    }
  });

  const setPermissions = action((state, payload) => {
    state.permissions = payload;
  });

  const setServerOffline = action((state, payload) => {
    logger.info("server is offline");

    state.serverOffline = true;
    state.user = null;
  });

  const setSessionExpired = action((state, payload) => {
    logger.info("session has expired");

    state.sessionExpired = true;
    state.user = null;
  });

  const setUser = action((state, payload) => {
    logger.info("set user");

    state.user = payload;
  });

  const registerResources = action((state, payload) => {
    R.forEach(object => {
      const security = R.prop("security", object);
      if (security) {
        state.resources[object._id] = R.slice(
          1,
          Infinity,
          R.reverse(R.split(".", R.prop("path", security)))
        );
      }
    }, payload);
  });

  return {
    // state
    serverOffline: false,
    sessionExpired: false,
    user: null,
    permissions: {},
    resources: {
      [ProtectedResource.appdock]: []
    },

    // computed
    hasPrivileges,

    // thunks
    login,
    logout,
    invalidateSession,
    invalidateToken,

    // listener
    onLogin,
    onResolveSecurity,

    // actions
    setPermissions,
    setServerOffline,
    setSessionExpired,
    setUser,
    registerResources
  };
};
