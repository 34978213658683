import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";

import { Menu } from "./menu";
import { useNavigateTo } from "./../../../router";

export const MenuContainer = () => {
  const navigateTo = useNavigateTo();

  const user = useStoreState(state => state.authentication.user);
  const logout = useStoreActions(actions => actions.authentication.logout);

  const modules = useStoreState(state => state.platform.modules);
  const activeModuleId = useStoreState(state => state.platform.activeModule);
  const uiShowMenu = useStoreState(state => state.platform.ui.showMenu);
  const uiShowProfile = useStoreState(state => state.platform.ui.showProfile);
  const activeModule = useStoreState(state =>
    state.platform.getModuleById(activeModuleId)
  );
  const uiSetShowMenu = useStoreActions(
    actions => actions.platform.uiSetShowMenu
  );
  const uiSetShowProfile = useStoreActions(
    actions => actions.platform.uiSetShowProfile
  );

  return (
    <Menu
      modules={modules}
      activeModule={activeModule}
      user={user}
      showMenu={uiShowMenu}
      showProfile={uiShowProfile}
      onShowMenu={() => {
        uiSetShowMenu(true);
      }}
      onDismissMenu={() => {
        uiSetShowMenu(false);
      }}
      onShowProfile={() => {
        uiSetShowProfile(true);
      }}
      onDismissProfile={() => {
        uiSetShowProfile(false);
      }}
      onSelectModule={module_ => {
        uiSetShowMenu(false);
        uiSetShowProfile(false);

        navigateTo(module_.main);
      }}
      onSignOut={() => {
        logout();
      }}
    />
  );
};
