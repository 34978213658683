import { useStoreActions, useStoreState } from "easy-peasy";
import * as R from "ramda";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useRouterHandler = () => {
  const { region: routerRegion } = useParams();

  const getRegionByAbbreviation = useStoreState(
    state => state.appDock.getRegionByAbbreviation
  );

  const uiRegionFilter = useStoreState(state => state.appDock.uiRegionFilter);
  const uiSetRegionFilter = useStoreActions(
    actions => actions.appDock.uiSetRegionFilter
  );

  useEffect(() => {
    if (routerRegion) {
      const regionToSet = getRegionByAbbreviation(routerRegion);

      if (regionToSet && !R.equals(regionToSet._id, uiRegionFilter)) {
        uiSetRegionFilter(regionToSet._id);
      }
    } else {
      if (!R.isNil(uiRegionFilter)) {
        uiSetRegionFilter(null);
      }
    }
  }, [
    routerRegion,
    getRegionByAbbreviation,
    uiRegionFilter,
    uiSetRegionFilter
  ]);
};
