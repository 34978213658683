import { useStoreState } from "easy-peasy";
import {
  ScrollablePane,
  getTheme,
  mergeStyleSets
} from "office-ui-fabric-react";
import * as R from "ramda";
import React from "react";

import { CategoryCard } from "./../components/category-card";
import { Menu } from "./../components/menu";
import { ProductEditPanel } from "./../components/product-edit-panel";

const theme = getTheme();

const styles = mergeStyleSets({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "header"
      "body"
    `,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0
  },
  header: {
    gridArea: "header"
  },
  body: {
    gridArea: "body",
    position: "relative",
    height: "100%"
  },
  categoriesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing.m
  },
  categories: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    margin: `-${theme.spacing.s1}`,
    width: "100%",
    selectors: {
      "@media(min-width: 1200px)": {
        width: "80%"
      }
    }
  }
});

export const View = () => {
  const categories = useStoreState(state => state.appDock.categoriesByProduct);

  return (
    <React.Fragment>
      <div className={styles.root}>
        <div className={styles.header}>
          <Menu />
        </div>
        <div className={styles.body}>
          <ScrollablePane>
            <div className={styles.categoriesWrapper}>
              <div className={styles.categories}>
                {R.map(
                  category => (
                    <CategoryCard key={category._id} {...category} />
                  ),
                  categories
                )}
              </div>
            </div>
          </ScrollablePane>
        </div>
      </div>
      <ProductEditPanel />
    </React.Fragment>
  );
};
