import { appDockModel } from "./model";
import { createAppDockService } from "./service";

export const createAppDockStore = ({ getApiClient }) => {
  return {
    model: appDockModel,
    service: createAppDockService({
      getApiClient
    })
  };
};
