import { Depths, MotionDurations, NeutralColors } from "@uifabric/fluent-theme";
import Color from "color";
import {
  Dialog,
  FontWeights,
  PrimaryButton,
  Text,
  getTheme,
  mergeStyles,
  mergeStyleSets
} from "office-ui-fabric-react";
import * as R from "ramda";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { ProductDetailsContainer } from "./product-details-container";
import { getProductStatusColor } from "./styles";

const theme = getTheme();

const styles = mergeStyleSets({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "header"
      "body"
    `,
    height: "100%",
    boxShadow: Depths.depth4,
    transition: `box-shadow ${MotionDurations.duration2}`,
    selectors: {
      ":hover": {
        boxShadow: Depths.depth16
      }
    }
  },
  header: {
    gridArea: "header",
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing.l1,
    userSelect: "none"
  },
  body: {
    gridArea: "body",
    padding: theme.spacing.l1,
    userSelect: "none"
  },
  productsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  products: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    margin: `-${theme.spacing.s1}`
  },
  product: {
    margin: theme.spacing.s1
  }
});

const ProductButton = ({ category, product }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const appParam = searchParams.get("app");

  const statusColor = getProductStatusColor(product.status);

  const baseStyle = mergeStyles({
    backgroundColor: statusColor,
    border: `2px solid ${statusColor}`,
    borderWidth: "0 0 2px 0",
    color: NeutralColors.white,
    transition: `all ${MotionDurations.duration2}`,
    transitionProperty: "background-color, box-shadow, color"
  });

  const style = {
    root: mergeStyles(baseStyle, {
      background: NeutralColors.white,
      color: NeutralColors.black
    }),
    rootHovered: mergeStyles(baseStyle, {
      boxShadow: theme.effects.elevation16
    }),
    rootPressed: baseStyle
  };

  const [showDialog, setShowDialog] = useState(appParam === product._id);

  if (appParam === product._id) {
    window.history.replaceState({}, document.title, `${window.location.origin}/#/appdock`);
  }

  return (
    <React.Fragment>
      <PrimaryButton
        styles={style}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        {product.name}
      </PrimaryButton>
      <Dialog
        hidden={!showDialog}
        minWidth={600}
        dialogContentProps={{
          styles: {
            header: {
              display: "none"
            },
            inner: {
              padding: 0,
              selectors: {
                "@media screen": {
                  padding: 0
                }
              }
            }
          }
        }}
        onDismiss={() => {
          setShowDialog(false);
        }}
      >
        <ProductDetailsContainer category={category} product={product} />
      </Dialog>
    </React.Fragment>
  );
};

export const CategoryDetails = ({ category, products }) => {
  const { name, description, color } = category;

  return (
    <div className={styles.root}>
      <div
        className={mergeStyles(styles.header, {
          color: NeutralColors.white,
          backgroundColor: color,
          background: `linear-gradient(45deg, ${color} 0%, ${Color(
            color
          ).lighten(0.2)} 100%)`
        })}
      >
        <Text
          className={mergeStyles({
            fontWeight: FontWeights.bold,
            textShadow: `1px 1px 2px ${NeutralColors.gray100}`,
            textTransform: "uppercase"
          })}
          variant="xLarge"
        >
          {name}
        </Text>
        {R.ifElse(R.isNil, R.always(null), text => <Text>{text}</Text>)(
          description
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.productsWrapper}>
          <div className={styles.products}>
            {R.map(product => {
              return (
                <div key={product._id} className={styles.product}>
                  <ProductButton category={category} product={product} />
                </div>
              );
            }, products)}
          </div>
        </div>
      </div>
    </div>
  );
};
